import { html } from 'lit';
import { __ } from 'i18n';
import { _converse, api } from '@converse/headless/core';
import '../styles/switch-workstations.scss';

export default (el) => {
    return el.model.models.map(data => {
        return html 
        `<div class="d-flex flex-column switch-workstations">
            <button @click=${ async (ev) => {
                await el.modeldata.save({selected_workstation: data.get('Company').id})
            }} type="button" class="btn btn-info px-3 mx-3 mt-4">${Array.from(data.get('Company').name)[0].toUpperCase()}</button>
        </div>`
    }); 
};