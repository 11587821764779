import dayjs from 'dayjs';
import log from '@converse/headless/log';
import u from '@converse/headless/utils/core';
import { _converse, api, converse } from '@converse/headless/core';
import { rejectMessage } from '@converse/headless/shared/actions';

import {
    StanzaParseError,
    getChatMarker,
    getChatState,
    getCorrectionAttributes,
    getEncryptionAttributes,
    getErrorAttributes,
    getMediaURLsMetadata,
    getOutOfBandAttributes,
    getReceiptId,
    getReferences,
    getRetractionAttributes,
    getSpoilerAttributes,
    getStanzaIDs,
    isArchived,
    isCarbon,
    isHeadline,
    isServerMessage,
    isValidReceiptRequest,
    throwErrorIfInvalidForward,
    isGroupAffiliationMessage,
    isForwardedMessage,
    isReactionMessage,
    isStorySeen,
    isMAMMessage,
} from '@converse/headless/shared/parsers';
import { omemo } from '../../../plugins/omemo/utils';
import { CHAT_TYPE, MESSAGE_TYPE } from '../muclight/constants';
import { LOCAL_STORAGE } from '../../../shared/constants';
import { decryptData, decryptDataWithCBC, encryptData } from '../../../utils/cryptoService';

const { Strophe, sizzle } = converse.env;


/**
 * Parses a passed in message stanza and returns an object of attributes.
 * @method st#parseMessage
 * @param { XMLElement } stanza - The message stanza
 * @param { _converse } _converse
 * @returns { (MessageAttributes|Error) }
 */
export async function parseMessage (stanza) {
    if (stanza.getAttribute('type')===CHAT_TYPE.GROUP_CHAT){
        return await parseGroupMessage(stanza)
    }
    return await parseChatMessage(stanza)
}

export async function parseChatMessage (stanza) {
    throwErrorIfInvalidForward(stanza);
    let to_jid = stanza.getAttribute('to');
    const to_resource = Strophe.getResourceFromJid(to_jid);
    if (api.settings.get('filter_by_resource') && to_resource && to_resource !== _converse.resource) {
        return new StanzaParseError(
            `Ignoring incoming message intended for a different resource: ${to_jid}`,
            stanza
        );
    }

    const original_stanza = stanza;
    let from_jid = stanza.getAttribute('from') || _converse.bare_jid;
    if (isCarbon(stanza)) {
        if (from_jid === _converse.bare_jid) {
            const selector = `[xmlns="${Strophe.NS.CARBONS}"] > forwarded[xmlns="${Strophe.NS.FORWARD}"] > message`;
            stanza = sizzle(selector, stanza).pop();
            to_jid = stanza.getAttribute('to');
            from_jid = stanza.getAttribute('from');
        } else {
            // Prevent message forging via carbons: https://xmpp.org/extensions/xep-0280.html#security
            rejectMessage(stanza, 'Rejecting carbon from invalid JID');
            return new StanzaParseError(`Rejecting carbon from invalid JID ${to_jid}`, stanza);
        }
    }

    const is_archived = isArchived(stanza);
    if (is_archived) {
        if (from_jid === _converse.bare_jid) {
            const selector = `[xmlns="${Strophe.NS.MAM}"] > forwarded[xmlns="${Strophe.NS.FORWARD}"] > message`;
            stanza = sizzle(selector, stanza).pop();
            to_jid = stanza.getAttribute('to');
            from_jid = stanza.getAttribute('from');
        } else {
            return new StanzaParseError(
                `Invalid Stanza: alleged MAM message from ${stanza.getAttribute('from')}`,
                stanza
            );
        }
    }
    const from_jid_resource = Strophe.getResourceFromJid(from_jid)
    const from_bare_jid = Strophe.getBareJidFromJid(from_jid);
    const is_me = from_bare_jid === _converse.bare_jid;
    if (is_me && to_jid === null) {
        return new StanzaParseError(
            `Don't know how to handle message stanza without 'to' attribute. ${stanza.outerHTML}`,
            stanza
        );
    }

    const is_headline = isHeadline(stanza);
    const is_server_message = isServerMessage(stanza);
    let contact, contact_jid;
    if (!is_headline && !is_server_message) {
        contact_jid = is_me ? Strophe.getBareJidFromJid(to_jid) : from_bare_jid;
        contact = await api.contacts.get(contact_jid);
        // if (contact === undefined && !api.settings.get('allow_non_roster_messaging')) {
        //     log.error(stanza);
        //     return new StanzaParseError(
        //         `Blocking messaging with a JID not in our roster because allow_non_roster_messaging is false.`,
        //         stanza
        //     );
        // }
    }
    /**
     * @typedef { Object } MessageAttributes
     * The object which {@link parseMessage} returns
     * @property { ('me'|'them') } sender - Whether the message was sent by the current user or someone else
     * @property { Array<Object> } references - A list of objects representing XEP-0372 references
     * @property { Boolean } editable - Is this message editable via XEP-0308?
     * @property { Boolean } is_archived -  Is this message from a XEP-0313 MAM archive?
     * @property { Boolean } is_carbon - Is this message a XEP-0280 Carbon?
     * @property { Boolean } is_delayed - Was delivery of this message was delayed as per XEP-0203?
     * @property { Boolean } is_encrypted -  Is this message XEP-0384  encrypted?
     * @property { Boolean } is_error - Whether an error was received for this message
     * @property { Boolean } is_headline - Is this a "headline" message?
     * @property { Boolean } is_markable - Can this message be marked with a XEP-0333 chat marker?
     * @property { Boolean } is_marker - Is this message a XEP-0333 Chat Marker?
     * @property { Boolean } is_only_emojis - Does the message body contain only emojis?
     * @property { Boolean } is_spoiler - Is this a XEP-0382 spoiler message?
     * @property { Boolean } is_tombstone - Is this a XEP-0424 tombstone?
     * @property { Boolean } is_unstyled - Whether XEP-0393 styling hints should be ignored
     * @property { Boolean } is_valid_receipt_request - Does this message request a XEP-0184 receipt (and is not from us or a carbon or archived message)
     * @property { Object } encrypted -  XEP-0384 encryption payload attributes
     * @property { String } body - The contents of the <body> tag of the message stanza
     * @property { String } chat_state - The XEP-0085 chat state notification contained in this message
     * @property { String } contact_jid - The JID of the other person or entity
     * @property { String } edited - An ISO8601 string recording the time that the message was edited per XEP-0308
     * @property { String } error_condition - The defined error condition
     * @property { String } error_text - The error text received from the server
     * @property { String } error_type - The type of error received from the server
     * @property { String } from - The sender JID
     * @property { String } fullname - The full name of the sender
     * @property { String } marker - The XEP-0333 Chat Marker value
     * @property { String } marker_id - The `id` attribute of a XEP-0333 chat marker
     * @property { String } msgid - The root `id` attribute of the stanza
     * @property { String } nick - The roster nickname of the sender
     * @property { String } oob_desc - The description of the XEP-0066 out of band data
     * @property { String } oob_url - The URL of the XEP-0066 out of band data
     * @property { String } origin_id - The XEP-0359 Origin ID
     * @property { String } receipt_id - The `id` attribute of a XEP-0184 <receipt> element
     * @property { String } received - An ISO8601 string recording the time that the message was received
     * @property { String } replace_id - The `id` attribute of a XEP-0308 <replace> element
     * @property { String } retracted - An ISO8601 string recording the time that the message was retracted
     * @property { String } retracted_id - The `id` attribute of a XEP-424 <retracted> element
     * @property { String } spoiler_hint  The XEP-0382 spoiler hint
     * @property { String } stanza_id - The XEP-0359 Stanza ID. Note: the key is actualy `stanza_id ${by_jid}` and there can be multiple.
     * @property { String } subject - The <subject> element value
     * @property { String } thread - The <thread> element value
     * @property { String } time - The time (in ISO8601 format), either given by the XEP-0203 <delay> element, or of receipt.
     * @property { String } to - The recipient JID
     * @property { String } type - The type of message
     */
    const delay = sizzle(`delay[xmlns="${Strophe.NS.DELAY}"]`, original_stanza).pop();
    const marker = getChatMarker(stanza);
    const now = new Date().toISOString();
    const fullname = JSON.parse(localStorage.getItem(LOCAL_STORAGE.KEY.USER)).profile.nickName || _converse.vcard?.get('fullname') || _converse.vcard?.get('nickname') || JSON.parse(localStorage.getItem(LOCAL_STORAGE.KEY.USER)).mobile || _converse.bare_jid;
    const is_group_affiliation = await isGroupAffiliationMessage(stanza)
    const bodyMessage = stanza.querySelector('body')?.textContent?.trim()
    let story_id = ``
    let story_data = ``
    if(stanza.querySelector('story_status')) {
        let bodyJson = JSON.parse(bodyMessage)
        story_id = bodyJson.storiesData ? bodyJson.storiesData[0].id : ``
    }
    let reply_msg_id = ``
    let reply_msg_data = ``
    if(stanza.querySelector('reply')) {
        reply_msg_id = stanza.querySelector('reply').getAttribute(`id`)
        const chatbox_recipient = await api.chatboxes.get(Strophe.getBareJidFromJid(stanza.getAttribute('from')))
        const chatbox_recipient_story_message = chatbox_recipient?.messages.findWhere({msgid: reply_msg_id})
        reply_msg_data = chatbox_recipient_story_message ? chatbox_recipient_story_message.attributes : ``
    }

    let is_reaction_message = stanza.querySelector('message_type')?.textContent?.trim()===MESSAGE_TYPE.REACTION && isReactionMessage(stanza).length>0
    let reaction_message_id = ''
    let fetch_single_reaction
    let reaction_reaction = []
    let reaction_removed = '0'
    const received_from_jid = Strophe.getBareJidFromJid(stanza.getAttribute('from'))
    if(is_reaction_message) {
        const reaction_data = stanza.querySelector('reaction')
        let reaction_data_reaction = reaction_data?.getAttribute(`reaction`) ? reaction_data?.getAttribute(`reaction`) : '[]'
        const reaction_data_reaction_to_be_updated = []
        const reaction_data_removed = reaction_data?.getAttribute(`removed`)
        reaction_removed = reaction_data_removed
        reaction_message_id = reaction_data?.getAttribute(`id`)
        const chatbox_for_reaction_message = await api.chatboxes.get(Strophe.getBareJidFromJid(stanza.getAttribute('from')),{},true)
        const chatbox_for_reaction_message_data = chatbox_for_reaction_message?.messages.findWhere({msgid: reaction_message_id})
        const get_random_roster = _converse.roster.findWhere({chat_type:'chat'})? _converse.roster.findWhere({chat_type:'chat'})?.attributes : _converse.roster.models[0]?.attributes
        if(reaction_removed=='0' || reaction_removed==null) {
            try {
                reaction_data_reaction = JSON.parse(reaction_data_reaction)
                for(const reaction_data_reactionV of reaction_data_reaction){
                    if(reaction_data_reactionV?.user===received_from_jid){
                        fetch_single_reaction = reaction_data_reactionV?.reaction
                    }
                    
                    const user_info = reaction_data_reactionV?.user===_converse.bare_jid ? {...get_random_roster,..._converse.xmppstatus.vcard.attributes} : _converse.roster.get(reaction_data_reactionV?.user)?.attributes
                    reaction_data_reaction_to_be_updated.push({...reaction_data_reactionV,...user_info, ...{reaction_message_id: stanza.getAttribute('id') || original_stanza.getAttribute('id')}})
                }
                chatbox_for_reaction_message_data?.save({reaction_data: reaction_data_reaction_to_be_updated})
                api.trigger('refresh_chat_message')
            }catch(error) {
                console.log(error)
            }
        }else{
            try{
                reaction_data_reaction = JSON.parse(reaction_data_reaction)
                const available_reaction = []
                for(const reaction_data_reaction_data of reaction_data_reaction) {
                    available_reaction.push(reaction_data_reaction_data.user)
                }
                const existing_chatbox_for_reaction_message_data_reaction_data = chatbox_for_reaction_message_data?.get(`reaction_data`) ? chatbox_for_reaction_message_data?.get(`reaction_data`) : []
                let existing_chatbox_for_reaction_message_data_reaction_data_filter_i = 0
                for(const existing_chatbox_for_reaction_message_data_reaction_data_filter of existing_chatbox_for_reaction_message_data_reaction_data) {
                    if(!available_reaction.includes(existing_chatbox_for_reaction_message_data_reaction_data_filter.jid)) {
                        existing_chatbox_for_reaction_message_data_reaction_data.splice(existing_chatbox_for_reaction_message_data_reaction_data_filter_i,1)
                    }
                    existing_chatbox_for_reaction_message_data_reaction_data_filter_i++
                }
                chatbox_for_reaction_message_data?.save({reaction_data: existing_chatbox_for_reaction_message_data_reaction_data})
                api.trigger('refresh_chat_message')    
            }catch(error) {
                console.log(error)
            }
        }
        reaction_reaction = reaction_data_reaction
    }

    let attrs = Object.assign(
        {
            contact_jid,
            from_jid_resource,
            is_archived,
            is_headline,
            is_server_message,
            'body': stanza.querySelector('body')?.textContent?.trim(),
            'chat_state': getChatState(stanza),
            'from': Strophe.getBareJidFromJid(stanza.getAttribute('from')),
            'is_carbon': isCarbon(original_stanza),
            'is_deleted': false,
            'is_delayed': !!delay,
            is_group_affiliation,
            'is_mam': isMAMMessage(stanza),
            'is_markable': !!sizzle(`markable[xmlns="${Strophe.NS.MARKERS}"]`, stanza).length,
            'is_marker': !!marker,
            'is_unstyled': !!sizzle(`unstyled[xmlns="${Strophe.NS.STYLING}"]`, stanza).length,
            'marker_id': marker && marker.getAttribute('id'),
            'msgid': stanza.getAttribute('id') || original_stanza.getAttribute('id'),
            'nick': Strophe.getBareJidFromJid(stanza.getAttribute('from')) === _converse.bare_jid ? fullname : _converse.roster.get(Strophe.getBareJidFromJid(stanza.getAttribute('from')))?.get(`nickname`) ? _converse.roster.get(Strophe.getBareJidFromJid(stanza.getAttribute('from')))?.get(`nickname`) : from_jid_resource,
            'receipt_id': getReceiptId(stanza),
            'received': new Date().toISOString(),
            'references': getReferences(stanza),
            'sender': is_me ? 'me' : 'them',
            'subject': stanza.querySelector('subject')?.textContent,
            'thread': stanza.querySelector('thread')?.textContent,
            'time': delay ? dayjs(delay.getAttribute('stamp')).toISOString() : now,
            'to': stanza.getAttribute('to'),
            'type': stanza.getAttribute('type') || 'normal',
            'message_type': stanza.querySelector('message_type')?.textContent?.trim(),
            'is_story': stanza.querySelector('story_status') ? true: false,
            'story_id': story_id,
            'is_story_reply': false,
            'story_data': ``,
            is_reaction_message,
            reaction_message_id,
            reaction_reaction,
            reaction_removed,
            'reaction_data': [],
            reply_msg_id,
            reply_msg_data,
            is_forward: isForwardedMessage(stanza)
        },
        getErrorAttributes(stanza),
        getOutOfBandAttributes(stanza),
        getSpoilerAttributes(stanza),
        getCorrectionAttributes(stanza, original_stanza),
        getStanzaIDs(stanza, original_stanza),
        getRetractionAttributes(stanza, original_stanza),
        getEncryptionAttributes(stanza, _converse)
    );
    if(attrs.message_type===MESSAGE_TYPE.WEBRTC || stanza.querySelector('webrtc')) {
        var call_data = await decryptDataWithCBC(stanza.querySelector('body')?.textContent?.trim())
        try {
            call_data = JSON.parse(call_data)
        }catch(e) {
        }
        attrs = {...attrs, ...call_data}
    }
    !_converse?.messageIds?.includes(attrs.msgid) ? _converse.messageIds.push(attrs.msgid) : ``
    if (attrs.is_archived) {
        const from = original_stanza.getAttribute('from');
        if (from && from !== _converse.bare_jid) {
            return new StanzaParseError(`Invalid Stanza: Forged MAM message from ${from}`, stanza);
        }
    }
    await api.emojis.initialize();
    attrs = Object.assign(
        {
            'message': attrs.body || attrs.error, // TODO: Remove and use body and error attributes instead
            'is_only_emojis': attrs.body ? u.isOnlyEmojis(attrs.body) : false,
            'is_valid_receipt_request': isValidReceiptRequest(stanza, attrs)
        },
        attrs
    );

    // We prefer to use one of the XEP-0359 unique and stable stanza IDs
    // as the Model id, to avoid duplicates.
    attrs['id'] = attrs['origin_id'] || attrs[`stanza_id ${attrs.from}`] || u.getUniqueId();

    /**
     * *Hook* which allows plugins to add additional parsing
     * @event _converse#parseMessage
     */
    let { message_type } = attrs
    if(message_type==72) {
        let reply_story_id = stanza.querySelector('story').getAttribute(`story_id`)
        const chatbox = await api.chatboxes.get(_converse.bare_jid)
        const chat_box_story_message = chatbox?.messages.findWhere({story_id: reply_story_id})
        attrs.reply_story_id = reply_story_id
        attrs.is_story_reply = true
        attrs.story_data = chat_box_story_message ? chat_box_story_message.attributes : ``
    }else{
        const chatbox_contact = await api.chatboxes.get(Strophe.getBareJidFromJid(attrs.contact_jid),{},true)
        const verify_if_message_already_exist = chatbox_contact?.messages?.findWhere({ msgid: attrs.msgid})
        const is_plain_text_exist = verify_if_message_already_exist?.get(`plaintext`) ? true : false
        if(!is_plain_text_exist) {
            attrs.is_reaction_message && fetch_single_reaction ? Object.assign(attrs, { 'plaintext': fetch_single_reaction }) : ``
            attrs.is_encrypted && !attrs.is_reaction_message ? attrs = await api.hook('parseMessage', stanza, attrs) : ``
        }
    }

    if(isStorySeen(stanza)) {
        let  story_seen_id = stanza.querySelector('story')?.getAttribute(`story_id`)
        const chatbox_logged = await api.chatboxes.get(_converse.bare_jid)
        let story_seen_msg = chatbox_logged.messages.findWhere({story_seen_id: story_seen_id, is_story_seen: true})
        let story_seen_data = story_seen_msg ? story_seen_msg.get(`story_seen_data`) ? story_seen_msg.get(`story_seen_data`) : [] : []
        !story_seen_data.includes(Strophe.getBareJidFromJid(stanza.getAttribute('from'))) ? story_seen_data.push(Strophe.getBareJidFromJid(stanza.getAttribute('from'))) : []
        
        if(story_seen_msg) {
            // existing message
            story_seen_msg.save({story_seen_data: story_seen_data})
        }else{
            let story_attrs = attrs
            story_attrs.id = story_seen_id
            story_attrs.story_seen_id = story_seen_id
            story_attrs.msgid = story_seen_id
            story_attrs.is_story_seen = true
            story_attrs.story_seen_data = story_seen_data
            await chatbox_logged.createMessage(attrs)    
        }
    }

    (attrs.is_encrypted && attrs.plaintext) && (!attrs.error_text && !attrs.error_type) ? _converse.roster.get(Strophe.getBareJidFromJid(contact_jid))?.save({hidden:false}) : ``
    if (attrs.is_story) {
        const storydataJid = _converse.story.get(attrs.contact_jid)
        const parseBody = JSON.parse(attrs.body)
        if(parseBody.type=="delete") {
            if(storydataJid) {
                const stories = storydataJid.get('stories')
                const check = stories.filter(data => data.story_id===parseBody.storyId)
                check.map(data => {
                    const index = stories.indexOf(data)
                    if(index!=="-1") {
                        stories.splice(index,1)
                    }
                })
                stories.length>0 ? await storydataJid.save({stories,...stories[stories.length-1],...{id: stories[stories.length-1].contact_jid,user_id: Strophe.getNodeFromJid(stories[stories.length-1].contact_jid),jid: stories[stories.length-1].contact_jid, nickname: _converse.roster.get(stories[stories.length-1].contact_jid)?.get('nickname') || _converse.roster.get(stories[stories.length-1].contact_jid)?.get('nick'), cover_image: _converse.roster.get(stories[stories.length-1].contact_jid)?.get('image'), cover_image_type: _converse.roster.get(stories[stories.length-1].contact_jid)?.get('image_type')}})
                : await storydataJid.destroy()

            }
        }else if(storydataJid ) {
            const stories = storydataJid.get('stories')
            const check = storydataJid.get('stories').filter(data=>data.story_id===attrs.story_id)
            const storyExpiry = parseInt(parseBody.postDate)+24 * 3600*1000
            const nowTime = Date.now()
            if(check.length===0 && storyExpiry-new Date(nowTime)>0) {
                stories.push(attrs)
                stories.sort((date1, date2) => {
                    const date1_body = JSON.parse(date1.body)
                    const date2_body = JSON.parse(date2.body)
                    return parseInt(date2_body.postDate) - parseInt(date1_body.postDate)
                })
                await storydataJid.save({stories,...attrs,...{id: attrs.contact_jid,postDate: parseBody?.postDate ? new Date(parseInt(parseBody?.postDate)).toISOString() : new Date().toISOString(), user_id: Strophe.getNodeFromJid(attrs.contact_jid),jid: attrs.contact_jid, nickname: _converse.roster.get(attrs.contact_jid)?.get('nickname') || _converse.roster.get(attrs.contact_jid)?.get('nick'), cover_image: _converse.roster.get(attrs.contact_jid)?.get('image'), cover_image_type: _converse.roster.get(attrs.contact_jid)?.get('image_type')}})
            }
        }else{
            const storyExpiry = parseInt(parseBody.postDate)+24 * 3600*1000
            const nowTime = Date.now()
            if(storyExpiry-new Date(nowTime)>0) {
                await _converse.story.create({stories: [attrs],...attrs,...{id: attrs.contact_jid,postDate: parseBody?.postDate ? new Date(parseInt(parseBody?.postDate)).toISOString() : new Date().toISOString(), user_id: Strophe.getNodeFromJid(attrs.contact_jid),jid: attrs.contact_jid, nickname: _converse.roster.get(attrs.contact_jid)?.get('nickname') || _converse.roster.get(attrs.contact_jid)?.get('nick'), cover_image: _converse.roster.get(attrs.contact_jid)?.get('image'), cover_image_type: _converse.roster.get(attrs.contact_jid)?.get('image_type')}}, {sort: false})
            }
        }
    }

    // We call this after the hook, to allow plugins (like omemo) to decrypt encrypted
    // messages, since we need to parse the message text to determine whether
    // there are media urls.
    return Object.assign(attrs, getMediaURLsMetadata(attrs.is_encrypted ? attrs.plaintext : attrs.body));
}
export async function parseGroupMessage (stanza) {
    throwErrorIfInvalidForward(stanza);
    let to_jid = stanza.getAttribute('to');
    const to_resource = Strophe.getResourceFromJid(to_jid);
    if (api.settings.get('filter_by_resource') && to_resource && to_resource !== _converse.resource) {
        return new StanzaParseError(
            `Ignoring incoming message intended for a different resource: ${to_jid}`,
            stanza
        );
    }

    const original_stanza = stanza;
    let from_jid = stanza.getAttribute('from') || _converse.bare_jid;
    if (isCarbon(stanza)) {
        if (from_jid === _converse.bare_jid) {
            const selector = `[xmlns="${Strophe.NS.CARBONS}"] > forwarded[xmlns="${Strophe.NS.FORWARD}"] > message`;
            stanza = sizzle(selector, stanza).pop();
            to_jid = stanza.getAttribute('to');
            from_jid = stanza.getAttribute('from');
        } else {
            // Prevent message forging via carbons: https://xmpp.org/extensions/xep-0280.html#security
            rejectMessage(stanza, 'Rejecting carbon from invalid JID');
            return new StanzaParseError(`Rejecting carbon from invalid JID ${to_jid}`, stanza);
        }
    }

    const is_archived = isArchived(stanza);
    if (is_archived) {
        if (from_jid === _converse.bare_jid) {
            const selector = `[xmlns="${Strophe.NS.MAM}"] > forwarded[xmlns="${Strophe.NS.FORWARD}"] > message`;
            stanza = sizzle(selector, stanza).pop();
            to_jid = stanza.getAttribute('to');
            from_jid = stanza.getAttribute('from');
        } else {
            return new StanzaParseError(
                `Invalid Stanza: alleged MAM message from ${stanza.getAttribute('from')}`,
                stanza
            );
        }
    }
    const from_jid_resource = Strophe.getResourceFromJid(from_jid)
    const from_bare_jid = Strophe.getBareJidFromJid(from_jid);
    const is_me = from_bare_jid === _converse.bare_jid;
    if (is_me && to_jid === null) {
        return new StanzaParseError(
            `Don't know how to handle message stanza without 'to' attribute. ${stanza.outerHTML}`,
            stanza
        );
    }

    const is_headline = isHeadline(stanza);
    const is_server_message = isServerMessage(stanza);
    let contact, contact_jid;
    if (!is_headline && !is_server_message) {
        contact_jid = is_me ? Strophe.getBareJidFromJid(to_jid) : from_bare_jid;
        contact = await api.contacts.get(contact_jid);
        if (contact === undefined && !api.settings.get('allow_non_roster_messaging')) {
            log.error(stanza);
            return new StanzaParseError(
                `Blocking messaging with a JID not in our roster because allow_non_roster_messaging is false.`,
                stanza
            );
        }
    }

    /**
     * @typedef { Object } MessageAttributes
     * The object which {@link parseMessage} returns
     * @property { ('me'|'them') } sender - Whether the message was sent by the current user or someone else
     * @property { Array<Object> } references - A list of objects representing XEP-0372 references
     * @property { Boolean } editable - Is this message editable via XEP-0308?
     * @property { Boolean } is_archived -  Is this message from a XEP-0313 MAM archive?
     * @property { Boolean } is_carbon - Is this message a XEP-0280 Carbon?
     * @property { Boolean } is_delayed - Was delivery of this message was delayed as per XEP-0203?
     * @property { Boolean } is_encrypted -  Is this message XEP-0384  encrypted?
     * @property { Boolean } is_error - Whether an error was received for this message
     * @property { Boolean } is_headline - Is this a "headline" message?
     * @property { Boolean } is_markable - Can this message be marked with a XEP-0333 chat marker?
     * @property { Boolean } is_marker - Is this message a XEP-0333 Chat Marker?
     * @property { Boolean } is_only_emojis - Does the message body contain only emojis?
     * @property { Boolean } is_spoiler - Is this a XEP-0382 spoiler message?
     * @property { Boolean } is_tombstone - Is this a XEP-0424 tombstone?
     * @property { Boolean } is_unstyled - Whether XEP-0393 styling hints should be ignored
     * @property { Boolean } is_valid_receipt_request - Does this message request a XEP-0184 receipt (and is not from us or a carbon or archived message)
     * @property { Object } encrypted -  XEP-0384 encryption payload attributes
     * @property { String } body - The contents of the <body> tag of the message stanza
     * @property { String } chat_state - The XEP-0085 chat state notification contained in this message
     * @property { String } contact_jid - The JID of the other person or entity
     * @property { String } edited - An ISO8601 string recording the time that the message was edited per XEP-0308
     * @property { String } error_condition - The defined error condition
     * @property { String } error_text - The error text received from the server
     * @property { String } error_type - The type of error received from the server
     * @property { String } from - The sender JID
     * @property { String } fullname - The full name of the sender
     * @property { String } marker - The XEP-0333 Chat Marker value
     * @property { String } marker_id - The `id` attribute of a XEP-0333 chat marker
     * @property { String } msgid - The root `id` attribute of the stanza
     * @property { String } nick - The roster nickname of the sender
     * @property { String } oob_desc - The description of the XEP-0066 out of band data
     * @property { String } oob_url - The URL of the XEP-0066 out of band data
     * @property { String } origin_id - The XEP-0359 Origin ID
     * @property { String } receipt_id - The `id` attribute of a XEP-0184 <receipt> element
     * @property { String } received - An ISO8601 string recording the time that the message was received
     * @property { String } replace_id - The `id` attribute of a XEP-0308 <replace> element
     * @property { String } retracted - An ISO8601 string recording the time that the message was retracted
     * @property { String } retracted_id - The `id` attribute of a XEP-424 <retracted> element
     * @property { String } spoiler_hint  The XEP-0382 spoiler hint
     * @property { String } stanza_id - The XEP-0359 Stanza ID. Note: the key is actualy `stanza_id ${by_jid}` and there can be multiple.
     * @property { String } subject - The <subject> element value
     * @property { String } thread - The <thread> element value
     * @property { String } time - The time (in ISO8601 format), either given by the XEP-0203 <delay> element, or of receipt.
     * @property { String } to - The recipient JID
     * @property { String } type - The type of message
     */
    const delay = sizzle(`delay[xmlns="${Strophe.NS.DELAY}"]`, original_stanza).pop();
    const marker = getChatMarker(stanza);
    const now = new Date().toISOString();
    const fullname = JSON.parse(localStorage.getItem(LOCAL_STORAGE.KEY.USER)).profile.nickName || _converse.vcard?.get('fullname') || _converse.vcard?.get('nickname') || JSON.parse(localStorage.getItem(LOCAL_STORAGE.KEY.USER)).mobile || _converse.bare_jid;
    const is_group_affiliation = await isGroupAffiliationMessage(stanza)
    const bodyMessage = stanza.querySelector('body')?.textContent?.trim()

    let story_id = ``
    let story_data = ``
    if(stanza.querySelector('story_status')) {
        let bodyJson = JSON.parse(bodyMessage)
        story_id = bodyJson.storiesData ? bodyJson.storiesData[0].id : ``
    }

    let reply_msg_id = ``
    let reply_msg_data = ``
    if(stanza.querySelector('reply')) {
        reply_msg_id = stanza.querySelector('reply').getAttribute(`id`)
        const chatbox_recipient = await api.chatboxes.get(Strophe.getBareJidFromJid(stanza.getAttribute('from')))
        const chatbox_recipient_story_message = chatbox_recipient?.messages.findWhere({msgid: reply_msg_id})
        reply_msg_data = chatbox_recipient_story_message ? chatbox_recipient_story_message.attributes : ``
    }

    let is_reaction_message = stanza.querySelector('message_type')?.textContent?.trim()===MESSAGE_TYPE.REACTION && isReactionMessage(stanza).length>0
    let reaction_message_id = ''
    let reaction_reaction = []
    let reaction_removed = '0'
    if(is_reaction_message) {
        const reaction_data = stanza.querySelector('reaction')
        let reaction_data_reaction = reaction_data?.getAttribute(`reaction`) ? reaction_data?.getAttribute(`reaction`) : '[]'
        const reaction_data_reaction_to_be_updated = []
        const reaction_data_removed = reaction_data?.getAttribute(`removed`)
        reaction_removed = reaction_data_removed
        reaction_message_id = reaction_data?.getAttribute(`id`)

        const chatbox_for_reaction_message = await api.chatboxes.get(Strophe.getBareJidFromJid(stanza.getAttribute('from')),{},true)
        const chatbox_for_reaction_message_data = chatbox_for_reaction_message?.messages.findWhere({msgid: reaction_message_id})
        const get_random_roster = _converse.roster.findWhere({chat_type:'chat'})? _converse.roster.findWhere({chat_type:'chat'})?.attributes : _converse.roster.models[0]?.attributes
        if(reaction_removed=='0' || reaction_removed==null) {
            try {

                reaction_data_reaction = JSON.parse(reaction_data_reaction)
                for(const reaction_data_reactionV of reaction_data_reaction){
                    const user_info = reaction_data_reactionV?.user===_converse.bare_jid ? {...get_random_roster,..._converse.xmppstatus.vcard.attributes} : _converse.roster.get(reaction_data_reactionV?.user)?.attributes
                    reaction_data_reaction_to_be_updated.push({...reaction_data_reactionV,...user_info, ...{reaction_message_id: stanza.getAttribute('id') || original_stanza.getAttribute('id')}})
                }
                chatbox_for_reaction_message_data?.save({reaction_data: reaction_data_reaction_to_be_updated})
                api.trigger('refresh_chat_message')
            }catch(error) {
                console.log(error)
            }
        }else{
            try{
                reaction_data_reaction = JSON.parse(reaction_data_reaction)
                const available_reaction = []
                for(const reaction_data_reaction_data of reaction_data_reaction) {
                    available_reaction.push(reaction_data_reaction_data.user)
                }
                const existing_chatbox_for_reaction_message_data_reaction_data = chatbox_for_reaction_message_data?.get(`reaction_data`) ? chatbox_for_reaction_message_data?.get(`reaction_data`) : []
                let existing_chatbox_for_reaction_message_data_reaction_data_filter_i = 0
                for(const existing_chatbox_for_reaction_message_data_reaction_data_filter of existing_chatbox_for_reaction_message_data_reaction_data) {
                    if(!available_reaction.includes(existing_chatbox_for_reaction_message_data_reaction_data_filter.jid)) {
                        existing_chatbox_for_reaction_message_data_reaction_data.splice(existing_chatbox_for_reaction_message_data_reaction_data_filter_i,1)
                    }
                    existing_chatbox_for_reaction_message_data_reaction_data_filter_i++
                }
                // existing_chatbox_for_reaction_message_data_reaction_data.push(existing_chatbox_for_reaction_message_data_reaction_data_filter)
                chatbox_for_reaction_message_data?.save({reaction_data: existing_chatbox_for_reaction_message_data_reaction_data})
                api.trigger('refresh_chat_message')    
            }catch(error) {
                console.log(error)
            }
        }
        reaction_reaction = reaction_data_reaction
    }

    let attrs = Object.assign(
        {
            contact_jid,
            from_jid_resource,
            is_archived,
            is_headline,
            is_server_message,
            'body': stanza.querySelector('body')?.textContent?.trim(),
            'chat_state': getChatState(stanza),
            'from': Strophe.getBareJidFromJid(stanza.getAttribute('from')),
            'is_carbon': isCarbon(original_stanza),
            'is_deleted': false,
            'is_delayed': !!delay,
            is_group_affiliation,
            'is_mam': isMAMMessage(stanza),
            'is_markable': !!sizzle(`markable[xmlns="${Strophe.NS.MARKERS}"]`, stanza).length,
            'is_marker': !!marker,
            'is_unstyled': !!sizzle(`unstyled[xmlns="${Strophe.NS.STYLING}"]`, stanza).length,
            'marker_id': marker && marker.getAttribute('id'),
            'msgid': stanza.getAttribute('id') || original_stanza.getAttribute('id'),
            'nick': from_jid_resource===_converse.bare_jid ? fullname : _converse.roster.get(from_jid_resource)?.get(`nickname`),
            'receipt_id': getReceiptId(stanza),
            'received': new Date().toISOString(),
            'references': getReferences(stanza),
            'sender': is_me ? 'me' : 'them',
            'subject': stanza.querySelector('subject')?.textContent,
            'thread': stanza.querySelector('thread')?.textContent,
            'time': delay ? dayjs(delay.getAttribute('stamp')).toISOString() : now,
            'to': stanza.getAttribute('to'),
            'type': stanza.getAttribute('type') || CHAT_TYPE.GROUP_CHAT,
            'message_type': stanza.querySelector('message_type')?.textContent?.trim(),
            'is_story': stanza.querySelector('story_status') ? true: false,
            'story_id': story_id,
            'is_story_reply': false,
            'story_data': ``,
            is_reaction_message,
            reaction_message_id,
            reaction_reaction,
            reaction_removed,
            'reaction_data': [],
            reply_msg_id,
            reply_msg_data,
            is_forward: isForwardedMessage(stanza)
        },
        getErrorAttributes(stanza),
        getOutOfBandAttributes(stanza),
        getSpoilerAttributes(stanza),
        getCorrectionAttributes(stanza, original_stanza),
        getStanzaIDs(stanza, original_stanza),
        getRetractionAttributes(stanza, original_stanza),
        getEncryptionAttributes(stanza, _converse)
    );
    !_converse?.messageIds?.includes(attrs.msgid) ? _converse.messageIds.push(attrs.msgid) : ``
    if (attrs.is_archived) {
        const from = original_stanza.getAttribute('from');
        if (from && from !== _converse.bare_jid) {
            return new StanzaParseError(`Invalid Stanza: Forged MAM message from ${from}`, stanza);
        }
    }
    await api.emojis.initialize();
    attrs = Object.assign(
        {
            'message': attrs.body || attrs.error, // TODO: Remove and use body and error attributes instead
            'is_only_emojis': attrs.body ? u.isOnlyEmojis(attrs.body) : false,
            'is_valid_receipt_request': isValidReceiptRequest(stanza, attrs)
        },
        attrs
    );

    // We prefer to use one of the XEP-0359 unique and stable stanza IDs
    // as the Model id, to avoid duplicates.
    attrs['id'] = attrs['origin_id'] || attrs[`stanza_id ${attrs.from}`] || u.getUniqueId();

    /**
     * *Hook* which allows plugins to add additional parsing
     * @event _converse#parseMessage
     */
    attrs = await api.hook('parseMessage', stanza, attrs);
    (attrs.is_encrypted && attrs.plaintext) && (!attrs.error_text && !attrs.error_type) ? _converse.roster.get(Strophe.getBareJidFromJid(contact_jid))?.save({hidden:false}) : ``
    // We call this after the hook, to allow plugins (like omemo) to decrypt encrypted
    // messages, since we need to parse the message text to determine whether
    // there are media urls.
    return Object.assign(attrs, getMediaURLsMetadata(attrs.is_encrypted ? attrs.plaintext : attrs.body));
}
