import 'shared/components/icons.js';
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import { html } from "lit";
import { isUniView } from '@converse/headless/utils/core.js';
import { repeat } from 'lit/directives/repeat.js';
import { toggleGroup } from '../utils.js';
import { roster_section_edit } from '@converse/headless/plugins/chat/utils.js';

import '../styles/roster.scss';

const { u } = converse.env;


function renderContact (contact) {
    if(contact.get(`hidden`)) {
        return ``
    }
    const jid = contact.get('jid');
    const extra_classes = [];
    if (isUniView()) {
        const chatbox = _converse.chatboxes.get(jid);
        if (chatbox && !chatbox.get('hidden')) {
            extra_classes.push('open');
        }
    }
    const ask = contact.get('ask');
    const requesting  = contact.get('requesting');
    const subscription = contact.get('subscription');
    if ((ask === 'subscribe') || (subscription === 'from')) {
        /* ask === 'subscribe'
         *      Means we have asked to subscribe to them.
         *
         * subscription === 'from'
         *      They are subscribed to us, but not vice versa.
         *      We assume that there is a pending subscription
         *      from us to them (otherwise we're in a state not
         *      supported by converse.js).
         *
         *  So in both cases the user is a "pending" contact.
         */
        extra_classes.push('pending-xmpp-contact');
    } else if (requesting === true) {
        extra_classes.push('requesting-xmpp-contact');
    } else if (subscription === 'both' || subscription === 'to' || u.isSameBareJID(jid, _converse.connection.jid)) {
        extra_classes.push('current-xmpp-contact');
        extra_classes.push(subscription);
        extra_classes.push(contact.presence.get('show'));
    }
    const selectedJids = _converse.roster_filter.get('selected_jids') || [];
    const isChecked = selectedJids.includes(contact.get('jid'));

    return html`
        <li
            class="list-item d-flex controlbox-padded ${extra_classes.join(' ')}"
            data-status="${contact.presence.get('show')}"
        >
            ${_converse.roster_filter.get(`roster_section_edit`)
                    ? html`<div style="padding: 10px;">
                          <input
                              type="checkbox"
                              @click=${() => {
                                  triggered_data(contact.get(`jid`));
                              }}
                              jid=${contact.get(`jid`)}
                              name="roster_section_edit[]"
                              .checked=${isChecked}
                          />
                      </div>`
                : ``}
            <converse-roster-contact .model=${contact}></converse-roster-contact>
        </li>
        <hr class="contact-hr" />
    `;
}

function triggered_data(jid) {
    api.trigger('refresh_contact_list',jid)
}
export default  (o) => {
    const i18n_title = __('Click to hide these contacts');
    const collapsed = _converse.roster.state.get('collapsed_groups');
    return html` <div class="roster-group" data-group="${o.name}">
        <a
            href="#"
            class="list-toggle group-toggle controlbox-padded d-none"
            title="${i18n_title}"
            @click=${ev => toggleGroup(ev, o.name)}
        >
            <converse-icon
                color="var(--chat-head-color-dark)"
                size="1em"
                class="fa ${collapsed.includes(o.name) ? 'fa-caret-right' : 'fa-caret-down'}"
            ></converse-icon>
            ${o.name}
        </a>
        ${_converse.roster_filter.get(`roster_section_edit`)
            ? html`<div
                  class="d-flex align-items-center justify-content-between px-4"
                  style="background: #F6F6F6; height: 50px; width: 100%;"
              >
                  <div class="d-flex align-items-center justify-content-between" style="width: 36%;">
                      <img
                          src="../../../../images/chat-sidebar/cross.svg"
                          alt="close"
                          class="cursor-pointer"
                          @click=${() => {
                              _converse.roster_filter.save({
                                  roster_section_edit: !_converse.roster_filter.get(`roster_section_edit`),
                                  selected_jids: [],
                                  roster_section_edit_dropdown: false,
                              });
                          }}
                      />
                      <div>${_converse.roster_filter.get(`selected_jids`)?.length || 'No'} chat(s) selected</div>
                  </div>
                  <div
                      class="d-flex align-items-center h-75"
                      role="button"
                      @click=${() => {
                          _converse.roster_filter.save({
                              roster_section_edit_dropdown:
                                  !_converse.roster_filter.get(`roster_section_edit_dropdown`),
                          });
                      }}
                  >
                      <img src="../../../../images/chat-sidebar/options.svg" alt="options" />
                  </div>
              </div>`
            : ``}
        <ul
            class="items-list roster-group-contacts ${collapsed.includes(o.name) ? 'collapsed' : ''}"
            data-group="${o.name}"
        >
            ${_converse.roster_filter.get(`roster_section_edit_dropdown`)
                ? html`<form class="converse-form roster_section_edit position-relative" style="padding: 0 !important;">
                      <div class="editDropdown">
                          <div
                              class="w-100 d-flex justify-content-between align-items-center px-4 py-2 editOptionsDiv"
                              role="button"
                          >
                              <input
                                  class="editOptions"
                                  @click=${roster_section_edit}
                                  type="button"
                                  name="read"
                                  value="Read All"
                              />
                              <img src="../../../../images/general_assets/read-all.svg" alt="read-all" />
                          </div>
                          <div
                              class="w-100 d-flex justify-content-between align-items-center px-4 py-2 editOptionsDiv"
                              role="button"
                          >
                              <input
                                  class="editOptions"
                                  @click=${roster_section_edit}
                                  type="button"
                                  name="archive"
                                  value="${_converse.roster_filter.get(`list_type`) === `archived`
                                      ? `Unarchived`
                                      : `Archive`}"
                              />
                              <img src="../../../../images/general_assets/archive.svg" alt="archive" />
                          </div>
                          <div
                              class="w-100 d-flex justify-content-between align-items-center px-4 py-2 editOptionsDiv"
                              role="button"
                          >
                              <input
                                  class="editOptions"
                                  @click=${roster_section_edit}
                                  type="button"
                                  name="delete"
                                  value="Delete"
                              />
                              <img src="../../../../images/general_assets/delete.svg" alt="delete" />
                          </div>
                      </div>
                      ${repeat(o.contacts, c => c.get('jid'), renderContact)}
                  </form>`
                : repeat(o.contacts, c => c.get('jid'), renderContact)}
            ${/*repeat(o.contacts, (c) => c.get('jid'), renderContact)*/ ``}
        </ul>
    </div>`;
}
