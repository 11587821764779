import { CustomElement } from 'shared/components/element.js';
import tpl_moment_upload from './templates/moment-upload.js';
import tpl_moment_upload_dracula from './templates/themes/dracula/moment-upload.js';
import { _converse, api } from '@converse/headless/core';
import { THEMES } from '../../shared/constants.js';

export default class AddMomentOptions extends CustomElement {
    static get properties() {
        return {
            data: { type: Object },
            width: { type: String },
            height: { type: String },
            nonce: { type: String }, // Used to trigger rerenders
        };
    }

    constructor() {
        super();
        this.width = 36;
        this.height = 36;
    }

    initialize() {
        api.listen.on('moment-upload', (jsonData) => {
            this.jsonData = jsonData;
            this.requestUpdate();
        });
    }

    render() {
        const theme = _converse.xmppstatus?.get('theme');
        return theme === THEMES.DRACULA ? tpl_moment_upload_dracula(this.jsonData) : tpl_moment_upload(this.jsonData);
    }
}

api.elements.define('converse-moment-upload', AddMomentOptions);