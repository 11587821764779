// These are all the view-layer plugins.
//
// For the full Converse build, this list serves
// as a whitelist (see src/converse.js) in addition to the
// CORE_PLUGINS list in src/headless/consts.js.
export const VIEW_PLUGINS = [
    'converse-adhoc-views',
    'converse-bookmark-views',
    'converse-chatboxviews',
    'converse-chatview',
    'converse-controlbox',
    'converse-dragresize',
    'converse-fullscreen',
    'converse-headlines-view',
    'converse-mam-views',
    'converse-minimize',
    'converse-modal',
    'converse-muc-views',
    'converse-muclight-views',
    'converse-notification',
    'converse-omemo',
    'converse-profile',
    'converse-push',
    'converse-register',
    'converse-roomslist',
    'converse-rootview',
    'converse-rosterview',
    'converse-singleton'
];

export const XMPP_CONNECTION_STATUS = {
    "ERROR": 0,
    "CONNECTING": 1,
    "CONNFAIL": 2,
    "AUTHENTICATING": 3,
    "AUTHFAIL": 4,
    "CONNECTED": 5,
    "DISCONNECTED": 6,
    "DISCONNECTING": 7,
    "ATTACHED": 8,
    "REDIRECT": 9,
    "CONNTIMEOUT": 10,
    "BINDREQUIRED": 11,
    "ATTACHFAIL": 12,
    "RECONNECTING": 13,
    "REGIFAIL": 14,
    "REGISTERED": 15,
    "CONFLICT": 16,
    "NOTACCEPTABLE": 18
}

export const LOCAL_STORAGE = {
    KEY: {
        USER: 'user',
        PEPNODE: 'PEPNODE',
        CHATDATA: 'chatData',
    },
};
export const MUCLIGHT = {
    DOMAIN: `muclight.xmppprod.hookzapp.com`,
    SHOW_CONTACTS: true,
    SUBJECT_INPUT_VISIBILITY: true

}
export const IV_KEY = {
    AES_IV:`4c767321263c7226`,
    AES_KEY:`664f2f376356245030255e365449375b`
}

export const CONST_URLs = {
    apiMUrl: 'https://apim.hookzapp.com/v2.0/',
    APIM_APIS: {
        fireBaseSessionBuild: `https://apim.hookzapp.com/v2.0/fireBaseSessionBuild`,
        uploadImageAndGetLink: `https://apim.hookzapp.com/v2.0/uploadImageAndGetLink`,
        getFireBaseSession: `https://apim.hookzapp.com/v2.0/getFireBaseSession`,
        getNearByRosters: `https://apim.hookzapp.com/v2.0/getNearByRosters`,
        deleteStory: `https://apim.hookzapp.com/v2.0/deleteStory`,
        syncContactsOnAppOpenViaCS: `https://apim.hookzapp.com/v2.0/syncContactsOnAppOpenViaCS`,
        userPepNodes: `https://apim.hookzapp.com/v3.0/getPepNode`,
        myCompanies: `https://apim.hookzapp.com/v3.0/my_companies`,
    },
    APIPRODURL: 'https://apiprod.hookzapp.com/api/',
    APIPRODURL_APIS: {
        userPepNodes: `https://apiprod.hookzapp.com/api/users/pep-nodes`,
        userGroup: `https://apiprod.hookzapp.com/api/users/groups`,
        userProfile: `https://apiprod.hookzapp.com/api/users/profile`,
        sendStoryWithPrivacy: `https://apiprod.hookzapp.com/api/story/sendStoryWithPrivacy`,
        uploadMedia: `https://apiprod.hookzapp.com/api/upload/story`
    },
    WEB_CHAT_URL: window.location.href.includes('http://localhost') ? `http://localhost:3003?session_id=` : `https://web.wingtrill.com?session_id=`,
    WEB_CHAT_CLEAR_SESSION_URL: window.location.href.includes('http://localhost')
        ? 'http://localhost:3000/clear-sessions'
        : `https://${window.location.hostname.replace('web', 'www')}/clear-sessions`,
};

export const THEMES = {
    DRACULA: 'dracula',
    DEFAULT: 'default'
}
