import { __ } from 'i18n/index.js';
import { getOOBURLMarkup } from 'utils/html.js';
import { html } from 'lit';
import { converse } from '../../../headless/core.js';

const tpl_edited_icon = (el) => {
    const i18n_edited = __('This message has been edited');
    return html`<converse-icon
        title="${i18n_edited}"
        class="fa fa-edit chat-msg__edit-modal mt-1 ml-2 pr-0"
        @click=${el.showMessageVersionsModal}
        size="1em"
    ></converse-icon>`;
};

const tpl_checkmark = () => {
    return html`<i style="color: rgb(30 209 86);" class="fa-solid fa-check-double"></i>`;
};

const tpl_received_checkmark = () => {
    return html`<i style="color: #a8aba1;" class="fa-solid fa-check-double"></i>`;
};

const tpl_sent_check_mark = () => {
    return html`<i style="color: #a8aba1;" class="fa-solid fa-check"></i>`;
}

export default (el) => {
    const is_displayed = el.model.get('is_displayed') ? el.model.get('is_displayed') : false
    const is_received = el.model.get('is_received') ? el.model.get('is_received') : false
    const i18n_show = __('Show more');
    const is_groupchat_message = el.model.get('type') === 'groupchat';
    const i18n_show_less = __('Show less');

    const tpl_spoiler_hint = html`
        <div class="chat-msg__spoiler-hint">
            <span class="spoiler-hint">${el.model.get('spoiler_hint')}</span>
            <a class="badge badge-info spoiler-toggle" href="#" @click=${el.toggleSpoilerMessage}>
                <converse-icon
                    size="1em"
                    color="var(--background)"
                    class="fa ${el.model.get('is_spoiler_visible') ? 'fa-eye-slash' : 'fa-eye'}"
                ></converse-icon>
                ${el.model.get('is_spoiler_visible') ? i18n_show_less : i18n_show}
            </a>
        </div>
    `;

    const spoiler_classes = el.model.get('is_spoiler')
        ? `spoiler ${el.model.get('is_spoiler_visible') ? '' : 'hidden'}`
        : '';
    let text = el.model.getMessageText();
    const show_oob = el.model.get('oob_url') && text !== el.model.get('oob_url');
    const message_type = el.model.get('message_type');

    let lat;
    let long;
    if (message_type == converse.MESSAGE_TYPE.LOCATION) {
        const latLong = text.replaceAll('geo:', '');
        const latLongArray = latLong.split(',');
        lat = (latLongArray[0]);
        long = latLongArray[1].split('@@@').length > 1 ? latLongArray[1].split('@@@')[0]: latLongArray[1]
        text = text.split('@@@')[1];
    }
    let image
    if (message_type == converse.MESSAGE_TYPE.IMAGE) {
        image = text.split('!!!')[0];
        text = text.split('!!!').length>1 ? text.split('!!!')[text.split('!!!').length-1] : ``
    }

    let video
    if(message_type === converse.MESSAGE_TYPE.VIDEO)
    {
            video = text?.split('|')
            if(video?.length<2) {
                video = text?.split('!!!')
                text = text.split('!!!').length>1 ? text.split('!!!')[text.split('!!!').length-1] : ``
            }else{
                text = text.split('|').length>1 ? text.split('|')[text.split('|').length-1] : ``
            }
            video = video ? video[0] : video
    }
    let audio
    if(message_type === converse.MESSAGE_TYPE.AUDIO)
    {
            audio = text?.split('|')
            if(audio?.length<2) {
                audio = text?.split('!!!')
                text = text.split('!!!').length>1 ? text.split('!!!')[text.split('!!!').length-1] : ``
            }else{
                text = text.split('|').length>1 ? text.split('|')[text.split('|').length-1] : ``
            }
            audio = audio ? audio[0] : audio
    }
    let document
    if(message_type === converse.MESSAGE_TYPE.DOCUMENT)
    {
        document = text?.split('|')
            if(document?.length<2) {
                document = text?.split('!!!')
                text = text.split('!!!').length>1 ? text.split('!!!')[text.split('!!!').length-1] : ``
            }else{
                text = text.split('|').length>1 ? text.split('|')[text.split('|').length-1] : ``
            }
            document = document ? document[0] : document
    }
    return html`
        ${el.model.get('is_spoiler') ? tpl_spoiler_hint : ''}
        ${el.model.get('subject') ? html`<div class="chat-msg__subject">${el.model.get('subject')}</div>` : ''}
        <div class="d-flex">
                    ${!!(lat && long)
                        ? html`<div>
                              <iframe
                                  id="iframeId"
                                  src="https://maps.google.com/maps?q=${lat},${long}&hl=es;&output=embed"
                                  className="rounded"
                                  height="200px"
                                  width="300px"
                              ></iframe></div>
                          `
                        : ''}
                        <div class="text-justify">
                        ${image || video || audio || document ? html`<converse-chat-message-body
                        class="chat-msg__text ${el.model.get('is_only_emojis')
                            ? 'chat-msg__text--larger'
                            : ''} ${spoiler_classes}"
                        .model="${el.model}"
                        hide_url_previews=${el.model.get('hide_url_previews')}
                        ?is_me_message=${el.model.isMeCommand()}
                        text="${image || video || audio || document}"
                    ></converse-chat-message-body>
                    <br/>
                    <converse-chat-message-body
                    class="chat-msg__text ${el.model.get('is_only_emojis')
                        ? 'chat-msg__text--larger'
                        : ''} ${spoiler_classes}"
                    .model="${el.model}"
                    hide_url_previews=${el.model.get('hide_url_previews')}
                    ?is_me_message=${el.model.isMeCommand()}
                    text="${text}"
                ></converse-chat-message-body>` : html`<converse-chat-message-body
                class="chat-msg__text ${el.model.get('is_only_emojis')
                    ? 'chat-msg__text--larger'
                    : ''} ${spoiler_classes}"
                .model="${el.model}"
                hide_url_previews=${el.model.get('hide_url_previews')}
                ?is_me_message=${el.model.isMeCommand()}
                text="${text}"
            ></converse-chat-message-body>`}
                        </div>
            ${el.model.get('edited') ? tpl_edited_icon(el) : ''}
            </div>
        ${show_oob ? html`<div class="chat-msg__media">${getOOBURLMarkup(el.model.get('oob_url'))}</div>` : ''}
        <div class="chat-msg__error">${el.model.get('error_text') || el.model.get('error')}</div>
        ${ "" 
            //el.model.get(`sender`)!==`me` ? `` : is_displayed ? tpl_checkmark() : is_received ? tpl_received_checkmark() : tpl_sent_check_mark()
        }
        `;
};
