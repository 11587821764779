import tpl_add_muclight from "./templates/add-muclight.js";
import tpl_add_muclight_footer from "./templates/add-muclight-footer.js";
import BaseModal from "plugins/modal/modal.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import './core.js'
import '../styles/add-muclight-modal.scss';
import { isNull } from "lodash-es";
import { html } from "lit";
import { CHAT_TYPE } from "@converse/headless/plugins/muclight/constants.js";

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;


export default class AddMUCLIGHTModal extends BaseModal {

    initialize () {
        super.initialize();
        this.listenTo(this.model, 'change:muclight_domain', () => this.render());
        this.listenTo(this.model, 'change:group_screen', () => this.render());
        this.listenTo(this.model, 'change:group_occupants_search', () => this.render());
        this.listenTo(this.model, 'change:group_name', () => this.render());
        this.listenTo(this.model, 'change:group_description', () => this.render());
        this.listenTo(this.model, 'change:group_cover', () => this.render());
        this.listenTo(this.model, 'change:group_occupants', () => this.render());
        this.listenTo(this.model, 'change:error', () => this.render());
        this.listenTo(this.model, 'change:alert', () => this.render());
        this.muclight_roomid_policy_error_msg = null;
        api.listen.on('refresh_group_occupants', () => {
            this.render()
        })
        this.render();
    }

    renderModal () {
        if(this.ev) {
            this.model.save({group_occupants_search: '', group_screen: 0, group_name: '', group_description: '', group_cover: '', group_occupants: [], error: '', alert: ''})
            delete this.ev
        }
        return tpl_add_muclight(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __((this.model.get('group_screen')===0 || this.ev) ? 'Select Participants to Add in Group' : 'Create New Group');
    }

    renderModalFooter() {
        return tpl_add_muclight_footer(this);
    }

    parseRoomDataFromEvent (form) { // eslint-disable-line class-methods-use-this
        const data = new FormData(form);
        const jid = data.get('chatroom')?.trim();
        let nick;
        if (api.settings.get('locked_muclight_nickname')) {
            nick = _converse.getDefaultMUCLIGHTNickname();
            if (!nick) {
                throw new Error("Using locked_muclight_nickname but no nickname found!");
            }
        } else {
            nick = data.get('nickname').trim();
        }
        return {
            'jid': jid,
            'nick': nick
        }
    }

    /*
    *   we make a json here by collecting data from a form
    *   form param has all the input fields
    *   Example to use : new FormData(form);
    *   required parms and valude are [groupname,groupsubject] :: Data Type String,
    *   required atleast 1 should be selected as jids :: Array
    *   Example to use/call this.parseMucLightRoomDataFromEvent(form) or import add-muclight.js
    *   any and make an object to call it.
    */
    parseMucLightRoomDataFromEvent (form) { // eslint-disable-line class-methods-use-this
        const data = new FormData(form);
        const groupname = data.get('groupname')?.trim();
        let groupsubject = data.get('groupsubject')?.trim();

        let jids = form.querySelectorAll('input[name="jids[]"]:checked')
        let dataObj = {
            name: groupname,
            subject: groupsubject,
            occupants: this.getCheckedBoxes(jids)
        }
        return dataObj

    }
    /*
    *   we make a json here by collecting data from a muc-info received from server
    *   the data which we receive from server we will pass in parseMucLightInfo to gather information
    *   Example to use : parseMucLightInfo(result);
    *   it must have attribute name as `type` and it's value should be result to verify
    *   that the informaiton found for the sent group jid or else not found or any error will
    *   return by server
    */
    parseMucLightInfo(result) {
        let groupParsedData = {}
        if(result.getAttribute(`type`)==`result`){
            let configurationElements = result.querySelector(`configuration`).childNodes
            for(const configurationElementsData of configurationElements){
                groupParsedData[configurationElementsData.nodeName] = configurationElementsData?.textContent
            }
            groupParsedData[`occupants`] = {}
            let occupantsElements = result.querySelector(`occupants`).childNodes
            let occupantsElementsDataI = 0
            for(const occupantsElementsData of occupantsElements){
                groupParsedData[`occupants`][occupantsElementsDataI] = {jid:occupantsElementsData?.textContent,type:occupantsElementsData.getAttribute(`affiliation`)}
                occupantsElementsDataI++
            }
        }
        return groupParsedData
    }

    /*
    *   collecting data from form to return only selected data by user in occupants array
    *   Example to use : getCheckedBoxes(checkboxes)
    *   it will collect only those jids which are selected by user to add occupants in room
    */
    getCheckedBoxes(checkboxes) {
        var checkboxesChecked = [];
        // loop over them all
        for (var i=0; i<checkboxes.length; i++) {
           // And stick the checked ones onto an array...
           if (checkboxes[i].checked) {
              checkboxesChecked.push(checkboxes[i].value);
           }
        }
        // Return the array if it is non-empty, or null
        return checkboxesChecked.length > 0 ? checkboxesChecked : [];
    }
    generateUserJid (jid) {
        if(isNull(jid)){
            return jid;
          }
          return `${Strophe.getNodeFromJid(jid)}@${_converse.domain}`
    }
    /*
    *   getGroupInfo to call server with specific group jid to request to return data
    *   based on groupjid
    *   Example to use : getGroupInfo(`groupjid@xmpp.muclight.domain.com`)
    *   it will send request by iq and and will receive instant response from serve
    */
    async getGroupInfo (groupJid) {
        return new Promise(async (resolve, reject) => {
            try{
                groupJid = `${Strophe.getNodeFromJid(groupJid)}@${api.settings.get('muclight_domain')}`
                var iq = $iq({
                    type: "get",
                    id: Math.floor(Math.random() * 9999999999),
                    to: groupJid
                  }).c("query", { xmlns: Strophe.NS.MUCLIGHT_INFO })
                  .up().up();
                  let result = await api.sendIQ(iq)
                  const resultParseData = this.parseMucLightInfo(result)
                resolve(resultParseData)
            }catch(error){
                reject(error)
            }
        })
    }

    /*
    *   createMuc to call server to execute or request to create new room
    *   with passed some params which allows to configure room settings
    *   Example to use : createMuc(ev) ev has as clickable event and data which will
    *   collect submitted form data also to know what use has subbmited to request
    */
    createMuc(el) {
        return new Promise(async (resolve, reject) => {
            try{
                const group_name = el.model.get('group_name')
                const group_cover = el.model.get('group_cover')
                const group_occupants = el.model.get('group_occupants') || []
                const group_description = el.model.get('group_description')
                if(group_occupants.length<1)
                {
                    return resolve(el.model.save({group_screen:0, alert: {
                        type: 'danger text-center',
                        message: 'Select atleast 1 Occupant to Create Group.'
                    }}))
                }
                if(!group_name)
                {
                    return resolve(el.model.save({alert: {
                        type: 'danger text-center',
                        message: 'Group Name is Mandatory.'
                    }}))
                }
                let muclight_domain = api.settings.get('muclight_domain')
                let groupJid = `${u.getUniqueId()}@${muclight_domain}`
                var iq = $iq({
                  type: "set",
                  id: u.getUniqueId(),
                  to: groupJid
                }).c("query", { xmlns: Strophe.NS.MUCLIGHT_CREATE })
                  .c("configuration")
                  .c("created_by").t(_converse.connection.jid).up()
                  .c("created_at").t(Date.now().toString()).up()
                  .c("hide_numbers").t("false").up()
                  .c("members_max_count").t("500").up()
                  .c("admin_can_post_only").t("false").up()
                  .c("is_forward_allowed").t("false").up()
                  .c("public_group").t("false").up()
                  .c("public_can_post_text").t("false").up()
                  .c("post_as_anonymous").t("false").up()
                  .c("group_creator_jid").t(Strophe.getBareJidFromJid(_converse.connection.jid)).up()
                  .c("roomname").t(group_name).up()
                  .c("image_url").t(group_cover).up()
                  .c("room_description").t(group_description).up()
                  .up()
                  .c("occupants");
                for (let contact of group_occupants) {
                  iq.c("user", { affiliation: "member" }).t(this.generateUserJid(contact)).up()
                }
                iq.up()
                  .up()
                  .up();
                let datareturned = await api.sendIQ(iq)
                let getgroupinfo = await this.getGroupInfo(groupJid)
                _converse.roster.create({ nickname:getgroupinfo.roomname, chat_type: CHAT_TYPE.GROUP_CHAT, info: getgroupinfo, image:getgroupinfo.image_url, ask:"none", groups:[], jid:groupJid, subscription: "both", stamp: Date.now() }, {sort: false});

                resolve(getgroupinfo)
                await _converse.roster.get(groupJid).openChat(true)
                api.trigger(`groupInfoRequestUpdate`)
                this.modal.hide();
            }catch(error){
                console.log(`createMuc error :`,error)
                reject(`createMuc error :`)
            }
        })
    }
    /*
    *   addParticipants will call server to execute or request to add new member in existing
    *   group
    *   Example to use : addParticipants(data) data is an object which holdes groupjid as
    *   data.to and data.jid as member we wish to add in group
    */
    async addParticipants (data) {
        return new Promise(async (resolve, reject) => {
            try{
                var iq = $iq({
                    type: "set",
                    id: u.getUniqueId(),
                    to: data.to
                  }).c("query", { xmlns: Strophe.NS.MUCLIGHT_AFFILIATIONS })
                    .c("user", {affiliation: Strophe.NS.MUCLIGHT_AFFILIATION_TYPE_MEMBER}).t(data.jid).up()
                    .up()
                    .up()
                let datareturned = await api.sendIQ(iq)
                resolve(datareturned)
                }catch(error){
                reject(error)
            }
        })
    }
    openChatRoom (ev) {
        ev.preventDefault();
        if (this.checkRoomidPolicy()) return;

        const data = this.parseRoomDataFromEvent(ev.target);
        if (data.nick === "") {
            // Make sure defaults apply if no nick is provided.
            data.nick = undefined;
        }
        let jid;
        if (api.settings.get('locked_muclight_domain') || (api.settings.get('muclight_domain') && !u.isValidJID(data.jid))) {
            jid = `${Strophe.escapeNode(data.jid)}@${api.settings.get('muclight_domain')}`;
        } else {
            jid = data.jid
            this.model.setDomain(jid);
        }

        api.rooms.open(jid, Object.assign(data, {jid}), true);
        ev.target.reset();
        this.modal.hide();
    }

    checkRoomidPolicy () {
        if (api.settings.get('muclight_roomid_policy') && api.settings.get('muclight_domain')) {
            let jid = this.querySelector('converse-autocomplete input').value;
            if (api.settings.get('locked_muclight_domain') || !u.isValidJID(jid)) {
                jid = `${Strophe.escapeNode(jid)}@${api.settings.get('muclight_domain')}`;
            }
            const roomid = Strophe.getNodeFromJid(jid);
            const roomdomain = Strophe.getDomainFromJid(jid);
            if (api.settings.get('muclight_domain') !== roomdomain ||
                api.settings.get('muclight_roomid_policy').test(roomid)) {
                this.muclight_roomid_policy_error_msg = null;
            } else {
                this.muclight_roomid_policy_error_msg = __('Groupchat id is invalid.');
                return true;
            }
            this.render();
        }
    }
}

api.elements.define('converse-add-muclight-modal', AddMUCLIGHTModal);
