import 'shared/components/font-awesome.js';
import { api } from '@converse/headless/core';
import { html } from 'lit';
import "../styles/hookzApp-no-flyout.scss";
export default () => {
    const extra_classes = api.settings.get('singleton') ? ['converse-singleton'] : [];
    extra_classes.push(`converse-${api.settings.get('view_mode')}`);
    return html`
        <converse-chats class="converse-chatboxes row no-gutters ${extra_classes.join(' ')}"></converse-chats>
        <div class="hookzApp">
            <div class="hookzApp-no-flyout">
                <div class="hookzApp-no-flyout-innerdiv">
                    <div class="hookzApp-no-flyout-innerdiv-img-div">
                        <img src="/images/general_assets/hookzApp-gadgets-icon.png" />
                    </div>
                    <div class="hookzApp-no-flyout-innerdiv-title-div">
                        <h3><span class="hookzApp-no-flyout-innerdiv-title-div-hook">Hook</span><span  class="hookzApp-no-flyout-innerdiv-title-div-z">z</span> <span  class="hookzApp-no-flyout-innerdiv-title-div-app">App -</span><span  class="hookzApp-no-flyout-innerdiv-title-div-desktop">Desktop</span> </h3>
                    </div>
                    <div class="hookzApp-no-flyout-innerdiv-text-div">
                        <div>Send and receive messages without keeping your phone online.</div>
                        <div>Use HookZApp on up to 5 linked devices and 1 phone at the same time</div>
                    </div>
                    <div class="hookzApp-no-flyout-innerdiv-button-div">
                        <button>Check our tips & Features</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="converse-modals" class="modals"></div>
        <converse-fontawesome></converse-fontawesome>
    `;
};
