import { __ } from 'i18n';
import { _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { modal_close_button } from "plugins/modal/templates/buttons.js";

import '../styles/add-muclight.scss';

export default (el) => {
    const i18n_button = __(`${el.model.get(`group_screen`)===0 ? `Add contacts` : `Create`}`);
    return html`
    <div class="modal-footer">
        ${modal_close_button}
        <button @click=${(ev) => el.model.get(`group_screen`)===0 ? el.model.save({group_screen: 1}) : el.createMuc(el)} class="btn btn-danger btn-rounded mt-2 create-btn" />${i18n_button}</button>
    </div>`
}
