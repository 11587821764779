import tpl_alert_component from "./modal-alert.js";
import { html } from "lit";
import { __ } from 'i18n';
import { modal_close_button } from "plugins/modal/templates/buttons.js";

export default (el) => {
    const alert = el.model?.get('alert');
    const level = el.model?.get('level') ?? '';
    return html`<div
                  class="modal-dialog"
                  role="document"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
              >
                  <div class="modal-content" style="width: 38vw; max-height: 92vh;">
                    <div class="modal-header ${level} mt-3 ml-2">
                        <h5 class="modal-title">${el.getModalTitle()}</h5>
                    </div>
                    <div class="modal-body p-0">
                        <span class="modal-alert">
                            ${alert
                                ? tpl_alert_component({ 'type': `alert-${alert.type}`, 'message': alert.message })
                                : ''}
                        </span>
                        ${el.renderModal?.() ?? ''}
                    </div>
                    ${el.renderModalFooter?.() ??
                    html`<div class="modal-footer">${modal_close_button}</div>`
                    }
                    </div>
              </div>
          </form>`
}
