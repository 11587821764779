define(function (require, exports, module) {
    const Helper = {
        escapeHtml (unsafe) {
            return unsafe.replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', '&quot;').replaceAll("'", '&#039;');
        },
        async geoLocation () {
            return new Promise( async (resolve, reject) => {
                try{
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition( (data) => {
                            resolve({
                                status: true,
                                message: "Success, Found",
                                data
                            })
                        });
                      } else {
                        resolve({
                            status: false,
                            message: "Geolocation is not supported by this browser",
                            data: []
                        })
                      }
                    }catch(e) {
                    reject(e)
                }
            })
        },

        diffInSeconds (timestampA, timestampB) {
            //  absolute value added incase you just want the diff but don't care which came first
            return (Math.abs(timestampB - timestampA)) / 1000
        },

        YesterdayTime () {
            var ts = Math.round(new Date().getTime());
            var tsYesterday = ts - (24 * 3600 *1000);
            return tsYesterday;
        },
        TomorrowTime () {
            var ts = Math.round(new Date().getTime());
            var tsTomorrow = ts - (24 * 3600 *1000);
            return tsTomorrow;
        },
        timeDistance (date1, date2) {
            let distance = Math.abs(date1 - date2);
            const hours = Math.floor(distance / 3600000);
            distance -= hours * 3600000;
            const minutes = Math.floor(distance / 60000);
            distance -= minutes * 60000;
            const seconds = Math.floor(distance / 1000);
            return `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
        }


    };
    module.exports = Helper;
});