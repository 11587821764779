import { html } from 'lit';
import { _converse, api } from '@converse/headless/core';
import { repeat } from 'lit/directives/repeat.js';

import '../styles/moments.scss';
function handleMyMomentsClick(e) {
    api.trigger('moment-upload', {
        step: 1,
        showBackBtn: false,
        optionSelected: false,
        cameraSeleted: false,
        videoSelected: false,
        librarySelected: false,
    });
}

function getMomentsManageButtons(model) {
    return html`<div @mouseleave=${ (ev) => model.save({drop_down: false})} class="flex-column align-items-center tooltip-list dropdown-menu ${model.get('drop_down') ? `show` : ``}">
        <div class="moment-tooltip-list-triangle-up"></div>
        <div class="list-group">
            <button
                type="button"
                class="list-group-item list-group-item-action d-flex justify-content-between"
                @click=${async () => {
                    await _converse.storydata.save({jid: ``, story_id: ``, view_list: false, story_span_width: 0, story_span_width_interval: ``})
                    await _converse.storydata.save({jid: _converse.bare_jid, story_id: _converse.story.get(_converse.bare_jid).get('stories')[0].story_id, view_list: false, story_span_width: 0, story_span_width_interval: ``})
            }}>
                <span>View</span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.19" height="8.793" viewBox="0 0 13.19 8.793">
                        <path
                            id="Icon_awesome-eye"
                            data-name="Icon awesome-eye"
                            d="M13.111,8.562A7.345,7.345,0,0,0,6.6,4.5,7.346,7.346,0,0,0,.08,8.563a.741.741,0,0,0,0,.668A7.345,7.345,0,0,0,6.6,13.293a7.346,7.346,0,0,0,6.515-4.063A.741.741,0,0,0,13.111,8.562ZM6.6,12.194a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,6.6,12.194Zm0-5.5a2.183,2.183,0,0,0-.58.087A1.1,1.1,0,0,1,4.484,8.317,2.193,2.193,0,1,0,6.6,6.7Z"
                            transform="translate(0 -4.5)"
                            fill="#003254"
                        />
                    </svg>
                </span>
            </button>
            <button
                type="button"
                class="list-group-item list-group-item-action d-flex justify-content-between"
                @click=${handleMyMomentsClick}
            >
                <span>Add</span>
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.191"
                        height="13.191"
                        viewBox="0 0 13.191 13.191"
                    >
                        <g
                            id="Icon_ionic-ios-add-circle-outline"
                            data-name="Icon ionic-ios-add-circle-outline"
                            transform="translate(-3.375 -3.375)"
                        >
                            <path
                                id="Path_14257"
                                data-name="Path 14257"
                                d="M16.759,13.381H14.4V11.019a.507.507,0,1,0-1.015,0v2.362H11.019a.486.486,0,0,0-.507.507.491.491,0,0,0,.507.507h2.362v2.362a.491.491,0,0,0,.507.507.5.5,0,0,0,.507-.507V14.4h2.362a.507.507,0,1,0,0-1.015Z"
                                transform="translate(-3.918 -3.918)"
                                fill="#003254"
                            />
                            <path
                                id="Path_14258"
                                data-name="Path 14258"
                                d="M9.971,4.263A5.706,5.706,0,1,1,5.934,5.934,5.67,5.67,0,0,1,9.971,4.263m0-.888a6.6,6.6,0,1,0,6.6,6.6,6.595,6.595,0,0,0-6.6-6.6Z"
                                transform="translate(0 0)"
                                fill="#003254"
                            />
                        </g>
                    </svg>
                </span>
            </button>
        </div>
    </div>`;
}


export default (el) => {
    let myMomentShared = false;
    let myMomentData = {};
    let i = 0;


    function updateScrollButtons(scrollContainer,scrollLeftBtn,scrollRightBtn) {
        const containerComputedStyle = window.getComputedStyle(scrollContainer);
        const containerScrollOffset = scrollContainer.getBoundingClientRect().left + parseFloat(containerComputedStyle.paddingLeft);
        const scrollPosition = scrollContainer.scrollLeft - containerScrollOffset;
        const isOverflowing = scrollContainer.scrollLeft < parseInt((scrollContainer.scrollWidth)-scrollContainer.getBoundingClientRect().width);
        scrollLeftBtn.style.display = scrollPosition > 0 ? 'block' : 'none';
        scrollRightBtn.style.display = isOverflowing ? 'block' : 'none';
    }

    function scrollMoments(direction,ev) {
        const scrollContainer = ev.target.parentElement
        let scrollContainerOffsetWidth = scrollContainer.offsetWidth;
        if (direction === 'left') {
            scrollContainerOffsetWidth = -scrollContainerOffsetWidth;
        }
        scrollContainer.scrollBy({ left: scrollContainerOffsetWidth, behavior: 'smooth' });
        setTimeout(()=> {
            updateScrollButtons(scrollContainer,scrollContainer.children[0],scrollContainer.children[1])
        },500)
    }

    return html`
        <div class="d-flex overflow-auto moment-parent align-items-center">
            <div class="scroll-container">
            ${el.model.length>3 ? 
            html `<img
            id="scroll-left"
            class="mt-3 position-absolute left-0 view-more cursor-pointer"
            src="../../../../images/moments_icons/left-moments-arrow.svg"
            alt="view more"
            @click=${(ev) => scrollMoments('left',ev)}
        />
        <img
        id="scroll-right"
        class="mt-3 position-absolute right-0 view-more cursor-pointer"
        src="../../../../images/moments_icons/right-moments-arrow.svg"
        alt="view more"
        @click=${(ev) => scrollMoments('right',ev)}
        />` : ``}
            

                <div class="d-flex">
                    ${el.model.length > 0 ? repeat(el.model, moment => moment.get('id'), moment => {
                        if(moment.get('from') === _converse.bare_jid) {
                            myMomentShared = true;
                            const body = JSON.parse(moment.get('body'));
                            let imageUrl = body?.avatarUrl ? body?.avatarUrl : (_converse.xmppstatus.vcard?.attributes?.image?.startsWith('data:') || _converse.xmppstatus.vcard?.attributes?.image?.startsWith('https://')) ? _converse.xmppstatus.vcard?.attributes?.image : `data:${_converse.xmppstatus.vcard?.attributes?.image_type};base64,${_converse.xmppstatus.vcard?.attributes?.image}`
                            let firstName;
                            if (body?.storiesData[0]?.mime_type?.includes('image')) {
                                imageUrl = body?.storiesData[0]?.url;
                            }
                            // if (body?.storiesData[0]?.mime_type?.includes('video')) {
                            //     imageUrl = body?.storiesData[0]?.url;
                            // }
                            firstName = moment.get('from') === _converse.bare_jid ? `My Moments` : body?.fullName?.split(' ')[0];
                            myMomentData = {
                                imageUrl,
                                firstName
                            }
                        }
                        i++;
                        if(el.model.length === i) {
                            if(myMomentShared) {
                                return html
                                        `<div class="d-flex flex-column justify-content-start align-items-center moment-media-parent" @mouseenter=${ (ev) => moment.save({drop_down: true})}>
                                            <div class="d-flex justify-content-center align-items-center position-relative moment-media-container">
                                                <img class="moment-media-image" src=${myMomentData.imageUrl} />
                                            </div>
                                            <div class="moment-user-name text-center">
                                                <p>${myMomentData.firstName}</p>
                                            </div>
                                        </div>${getMomentsManageButtons(moment)}`;
                                        

                            } else {
                                return html
                                        `<div class="d-flex flex-column justify-content-start align-items-center" @click=${handleMyMomentsClick}>
                                            <div class="d-flex justify-content-center align-items-center position-relative moment-media-container">
                                                <converse-avatar class="" .data=${_converse.xmppstatus.vcard?.attributes} nonce=${_converse.xmppstatus.vcard?.get('vcard_updated')} height="60px" width="60px"></converse-avatar>
                                                <img src="../../../../images/add-status.svg" style="position: absolute; bottom: 0; right: 0; width: 30%;"/>
                                            </div>
                                            <div class="moment-user-name text-center">
                                                <p>My Moments</p>
                                            </div>
                                        </div>`;
                            }
                        }
                    }) : html `<div class="d-flex flex-column justify-content-start align-items-center" @click=${handleMyMomentsClick}>
                            <div class="d-flex justify-content-center align-items-center position-relative moment-media-container">
                                <converse-avatar class="" .data=${_converse.xmppstatus.vcard?.attributes} nonce=${_converse.xmppstatus.vcard?.get('vcard_updated')} height="60px" width="60px"></converse-avatar>
                                <img src="../../../../images/add-status.svg" style="position: absolute; bottom: 0; right: 0; width: 30%;"/>
                            </div>
                            <div class="moment-user-name text-center">
                                <p>My Moments</p>
                            </div>
                        </div>`}
                    ${repeat(el.model, moment => moment.get('id'), moment => {
                        if(moment.get('from') !==_converse.bare_jid) {
                            const body = JSON.parse(moment.get('body'));
                            let imageUrl = body?.avatarUrl
                            let firstName;
                            if (body?.storiesData[0]?.mime_type?.includes('image')) {
                                imageUrl = body?.storiesData[0]?.url;
                            }
                            firstName = moment.get('from') === _converse.bare_jid ? `My Moments` : body?.fullName?.split(' ')[0] || body?.phoneNumber;
                            firstName = firstName !== "" ? firstName : "N/A";
                            return html
                                    `<div class="d-flex flex-column justify-content-start align-items-center moment-media-parent" @click=${async () => {
                                            await _converse.storydata.save({jid: ``, story_id: ``, view_list: false, story_span_width: 0, story_span_width_interval: ``})
                                            await _converse.storydata.save({jid: moment.get('contact_jid'), story_id:moment.get('stories')[0].story_id, view_list: false, story_span_width: 0, story_span_width_interval: ``})
                                        }} role="button">
                                        <div class="d-flex justify-content-center align-items-center position-relative moment-media-container">
                                            ${imageUrl ? html `<img class="moment-media-image" src=${imageUrl} />` : html `<converse-avatar class="" .data=${moment.attributes} nonce=${moment.attributes} height="60px" width="60px"></converse-avatar>`}
                                        </div>
                                        <div class="moment-user-name text-center">
                                            <p>${firstName}</p>
                                        </div>
                                    </div>`;  
                        }
                    })}
                </div>
            </div>
        </div>
    `;
};
