import { CustomElement } from 'shared/components/element.js';
import tpl_moments_header_span_listing from './templates/moments-header-span-listing.js';
import tpl_moments_header_span_listing_dracula from './templates/themes/dracula/moments-header-span-listing.js';
import { _converse, api } from '@converse/headless/core';
import { THEMES } from '../../shared/constants.js';


export default class MomentHeaderSpanListing extends CustomElement {

    static get properties () {
        return {
            data: { type: Object },
        }
    }
    constructor() {
        super();
    }

    initialize() {
            this.model = this.data.model
            this.story = this.data.story
            this.listenTo(this.model, 'add', () => this.requestUpdate());
            this.listenTo(this.model, 'destroy', () => this.requestUpdate());
            this.listenTo(this.model, 'remove', () => this.requestUpdate());
            this.listenTo(this.model, 'change:story_id', () =>  this.requestUpdate());    
            this.listenTo(this.model, 'change:view_list', () =>  this.requestUpdate());    
            this.listenTo(this.model, 'change:jid', () =>  this.requestUpdate());    
            this.listenTo(this.model, 'change:story_span_width', () =>  this.requestUpdate());    
            
            this.listenTo(this.story, 'add', () => this.requestUpdate());
            this.listenTo(this.story, 'destroy', () => this.requestUpdate());
            this.listenTo(this.story, 'remove', () => this.requestUpdate());
            this.listenTo(this.story, 'change:story_id', () =>  this.requestUpdate());    
            this.listenTo(this.story, 'change:jid', () =>  this.requestUpdate());    
            this.requestUpdate()
    }


    render  () {
        this.customSpanWidth()
        if(!this.model || !this.story || !this?.model?.get('jid')) {
            return ``
        }
        const theme = _converse.xmppstatus?.get('theme');
        return theme === THEMES.DRACULA ? tpl_moments_header_span_listing_dracula(this) : tpl_moments_header_span_listing(this);
    }

    customSpanWidth () {
        clearInterval(_converse.StorycustomSpanWidth)
        _converse.StorycustomSpanWidth = setInterval(async ()=> {
            if(!this.model || !this.story || !this?.model?.get('jid')) {
                return ``
            }
            let story_span_width_interval = this.model.get('story_span_width_interval') ? this.model.get('story_span_width_interval')+1 : 1
            let story_span_width = 100/30*(story_span_width_interval)
            if(story_span_width<101) {
                await this.model.save({story_span_width_interval,story_span_width})
            }
            if(story_span_width>=100 || story_span_width_interval>=30)
            {
                await this.model.save({story_span_width_interval:``,story_span_width:0})
                clearInterval(_converse.StorycustomSpanWidth)

            }
        },1000)    
    }

}

api.elements.define('converse-moment-header-span-listing', MomentHeaderSpanListing);
