import DOMPurify from 'dompurify';
import { __ } from 'i18n';
import { api, _converse } from '@converse/headless/core.js';
import { html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { getAutoCompleteList } from "../../search.js";
import { CHAT_TYPE } from '@converse/headless/plugins/muclight/constants.js';
import tpl_contactlisting from "./add-muclight-occupants.js";
import tpl_azcontactlisting from "./add-muclight-az-occupants.js";
import tpl_selected_occupants_list from "./selected-occupants-list.js"

import '../styles/add-muclight.scss';
import { populateContactsMap, shouldShowGroup } from '../../../rosterview/utils.js';
import { groupsComparator } from '@converse/headless/plugins/roster/utils.js';
import { repeat } from 'lit/directives/repeat.js';


export default (el,IGNORE_CHAT_TYPE='') => {
    const roster = _converse.roster || [];
    roster?.models?.sort((a, b) => a.get(`stamp`) - b.get(`stamp`)).reverse();
    const contacts_map = roster.reduce((acc, contact) => populateContactsMap(acc, contact), {});
    const groupnames = Object.keys(contacts_map).filter(shouldShowGroup);
    groupnames.sort(groupsComparator);
    const searchKey = el.model.get(`group_occupants_search`) || ``
    const selected_occupants = el.model.get('group_occupants') || []
    const selected_occupants_list = _converse.roster.models.filter(c => selected_occupants.includes(c.get('jid')))

    return html `
        <div class="new-chat-menu-search-bar-container px-4">
            <input
                @keyup=${ (ev) => {
                    el.model.save({group_occupants_search: ev.target.value})
                }}
                class="search-icon w-100 search-bar"
                type="text"
                value="${searchKey}"
                placeholder="${searchKey || `Search contacts`}"
            />
        </div>
        ${selected_occupants_list.length ? html `
        <div class="selected-occupants-list-container d-flex text-center">
                ${tpl_selected_occupants_list(selected_occupants_list,el)}
        </div>` : ``}
        <div class="${selected_occupants_list.length ? `contacts-list-with-occupants` : `contacts-list`}">
            <div class="frequently-contacted">
                <div class="frequently-contacted-heading pl-4 py-2 my-3">
                    <div class="heading-text">${searchKey ? `Search Contact` : `Frequently Contacted`}</div>
                </div>
                ${repeat(
                    groupnames,
                    n => n,
                    name => {
                        if(name === "New messages") {
                            return;
                        }
                        let contacts = contacts_map[name]
                        contacts = contacts.filter(c => {
                            return c.get(`chat_type`)!==IGNORE_CHAT_TYPE
                        });

                        contacts = contacts.filter(c => {
                            return searchKey ? c.get(`nickname`).toLowerCase().includes(searchKey.toLowerCase()) : true
                        });
                        if(!searchKey)
                        {
                            contacts.splice(3,contacts.length)    
                        }
                        // contacts.sort(contactsComparator);
                        return contacts.length ? tpl_contactlisting(el,{ contacts, name }) : '';
                    }
                )}
            </div>
            ${!searchKey ? html `
            <div class="alphabet-contacted">
                ${repeat(
                    groupnames,
                    n => n,
                    name => {
                        if(name === "New messages") {
                            return;
                        }
                        let contacts = contacts_map[name]
                        contacts = contacts.filter(c => {
                            return c.get(`chat_type`)!==IGNORE_CHAT_TYPE
                        });
                        let contact_data = []
                        for(const contactsdata of contacts) {
                            let nickname = contactsdata.get(`nickname`) || contactsdata.get(`jid`)
                            nickname = nickname?.trim()[0]?.toUpperCase()?.trim();
                            if(nickname==='+') {
                                nickname = contactsdata.get(`nickname`).trim()[1].toUpperCase().trim()
                            }
                            if(!contact_data[`${nickname}`]) {
                                contact_data[`${nickname}`] = []
                            }
                            contact_data[`${nickname}`].push(contactsdata)
                        }
                        contacts = contact_data
                        return contacts.length ? tpl_azcontactlisting(el,{ contacts, name }) : '';
                    }
                )}
            </div>
        </div>` : ``}`
}
