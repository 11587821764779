import { html } from 'lit';
import '../styles/file.scss';

// ${ hide_url ? '' : html`<a target="_blank" rel="noopener" href="${url}">${url}</a>` }
export default () =>
    html`<div class="controlbox-pane__setting-container settingsHidden">
        <div class="controlbox-pane__setting-subcontainer">
            <div class="back-btn-div">
                <div class="back-btn-div-btn">
                    <div><img src="/images/general_assets/left-arrow-svgrepo-com.svg" /></div>
                    <div>Back</div>
                </div>
            </div>
            <div class="content-div">
                <div class="switch-account">
                    <div><img src="/images/general_assets/hookzApp-diamond-red.png" /></div>
                    <div>HookZ Prime Subscription</div>
                </div>
                <div>
                    <div><img class="icons" src="/images/general_assets/hookzApp-arrow-right.png" /></div>
                </div>
            </div>
            <div class="content-div">
                <div class="switch-account">
                    <div><img src="/images/general_assets/hookzApp-suitcase-icon.png" /></div>
                    <div>Switch To Business Account</div>
                </div>
                <div>
                    <label class="form-switch">
                        <input type="checkbox" />
                        <i></i>
                    </label>
                </div>
            </div>
            <div class="content-div">
                <div class="switch-account">
                    <div><img src="/images/general_assets/hookzApp-clipz.png" /></div>
                    <div>ClipZ Settings</div>
                </div>
                <div>
                    <div><img class="icons" src="/images/general_assets/hookzApp-arrow-right.png" /></div>
                </div>
            </div>
            <div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-starred-message.png" /></div>
                        <div>Starred Messages</div>
                    </div>
                </div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-devices-green.png" /></div>
                        <div>Devices</div>
                    </div>
                </div>
            </div>
            <div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-phone-blue.png" /></div>
                        <div>Account</div>
                    </div>
                </div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-chat-green.png" /></div>
                        <div>Chats</div>
                    </div>
                </div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-notification-blue.png" /></div>
                        <div>Notifications</div>
                    </div>
                </div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-lock-yellow.png" /></div>
                        <div>Data and Storage</div>
                    </div>
                </div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-night-mode.png" /></div>
                        <div>Night Mode</div>
                    </div>
                    <div>
                        <label class="form-switch">
                            <input type="checkbox" />
                            <i></i>
                        </label>
                    </div>
                </div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-sticker-green.png" /></div>
                        <div>Stickers</div>
                    </div>
                </div>
            </div>
            <div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-question-yellow.png" /></div>
                        <div>Ask a Question</div>
                    </div>
                </div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-hook-red.png" /></div>
                        <div>Hook a Friend</div>
                    </div>
                </div>
                <div class="content-div merged">
                    <div class="switch-account">
                        <div><img src="/images/general_assets/hookzApp-term-condition-blue.png" /></div>
                        <div>Terms and Conditions</div>
                    </div>
                </div>
            </div>
        </div>
    </div>`;
