import URI from 'urijs';
import dayjs from 'dayjs';
import log from '@converse/headless/log';
import sizzle from 'sizzle';
import { Strophe } from 'strophe.js/src/strophe';
import { URL_PARSE_OPTIONS } from '@converse/headless/shared/constants.js';
import { _converse, api } from '@converse/headless/core';
import { decodeHTMLEntities } from '@converse/headless/utils/core.js';
import { rejectMessage } from '@converse/headless/shared/actions';
import {
    isAudioURL,
    isEncryptedFileURL,
    isImageURL,
    isVideoURL
} from '@converse/headless/utils/url.js';
import { CALL_TYPE, CALL_TYPE_CONST, CHAT_TYPE, MESSAGE_TYPE, SIGNAL_NAME, TURN_STUN_SERVER } from '../plugins/muclight/constants';

const { NS } = Strophe;

export class StanzaParseError extends Error {
    constructor (message, stanza) {
        super(message, stanza);
        this.name = 'StanzaParseError';
        this.stanza = stanza;
    }
}

/**
 * Extract the XEP-0359 stanza IDs from the passed in stanza
 * and return a map containing them.
 * @private
 * @param { XMLElement } stanza - The message stanza
 * @returns { Object }
 */
export function getStanzaIDs (stanza, original_stanza) {
    const attrs = {};
    // Store generic stanza ids
    const sids = sizzle(`stanza-id[xmlns="${Strophe.NS.SID}"]`, stanza);
    const sid_attrs = sids.reduce((acc, s) => {
        acc[`stanza_id ${s.getAttribute('by')}`] = s.getAttribute('id');
        return acc;
    }, {});
    Object.assign(attrs, sid_attrs);

    // Store the archive id
    const result = sizzle(`message > result[xmlns="${Strophe.NS.MAM}"]`, original_stanza).pop();
    if (result) {
        const by_jid = original_stanza.getAttribute('from') || _converse.bare_jid;
        attrs[`stanza_id ${by_jid}`] = result.getAttribute('id');
    }

    // Store the origin id
    const origin_id = sizzle(`origin-id[xmlns="${Strophe.NS.SID}"]`, stanza).pop();
    if (origin_id) {
        attrs['origin_id'] = origin_id.getAttribute('id');
    }
    return attrs;
}

export function getEncryptionAttributes (stanza) {
    const eme_tag = sizzle(`encryption[xmlns="${Strophe.NS.EME}"]`, stanza).pop();
    const namespace = eme_tag?.getAttribute('namespace');
    const attrs = {};
    if (namespace) {
        attrs.is_encrypted = true;
        attrs.encryption_namespace = namespace;
    } else if (sizzle(`encrypted[xmlns="${Strophe.NS.OMEMO}"]`, stanza).pop()) {
        attrs.is_encrypted = true;
        attrs.encryption_namespace = Strophe.NS.OMEMO;
    }
    return attrs;
}

/**
 * @private
 * @param { XMLElement } stanza - The message stanza
 * @param { XMLElement } original_stanza - The original stanza, that contains the
 *  message stanza, if it was contained, otherwise it's the message stanza itself.
 * @returns { Object }
 */
export function getRetractionAttributes (stanza, original_stanza) {
    const fastening = sizzle(`> apply-to[xmlns="${Strophe.NS.FASTEN}"]`, stanza).pop();
    if (fastening) {
        const applies_to_id = fastening.getAttribute('id');
        const retracted = sizzle(`> retract[xmlns="${Strophe.NS.RETRACT}"]`, fastening).pop();
        if (retracted) {
            const delay = sizzle(`delay[xmlns="${Strophe.NS.DELAY}"]`, original_stanza).pop();
            const time = delay ? dayjs(delay.getAttribute('stamp')).toISOString() : new Date().toISOString();
            return {
                'editable': false,
                'retracted': time,
                'retracted_id': applies_to_id
            };
        }
    } else {
        const tombstone = sizzle(`> retracted[xmlns="${Strophe.NS.RETRACT}"]`, stanza).pop();
        if (tombstone) {
            return {
                'editable': false,
                'is_tombstone': true,
                'retracted': tombstone.getAttribute('stamp')
            };
        }
    }
    return {};
}

export function getCorrectionAttributes (stanza, original_stanza) {
    const el = sizzle(`replace[xmlns="${Strophe.NS.MESSAGE_CORRECT}"]`, stanza).pop();
    if (el) {
        const replace_id = el.getAttribute('id');
        if (replace_id) {
            const delay = sizzle(`delay[xmlns="${Strophe.NS.DELAY}"]`, original_stanza).pop();
            const time = delay ? dayjs(delay.getAttribute('stamp')).toISOString() : new Date().toISOString();
            return {
                replace_id,
                'edited': time
            };
        }
    }
    return {};
}

export function getOpenGraphMetadata (stanza) {
    const fastening = sizzle(`> apply-to[xmlns="${Strophe.NS.FASTEN}"]`, stanza).pop();
    if (fastening) {
        const applies_to_id = fastening.getAttribute('id');
        const meta = sizzle(`> meta[xmlns="${Strophe.NS.XHTML}"]`, fastening);
        if (meta.length) {
            const msg_limit = api.settings.get('message_limit');
            const data = meta.reduce((acc, el) => {
                const property = el.getAttribute('property');
                if (property) {
                    let value = decodeHTMLEntities(el.getAttribute('content') || '');
                    if (msg_limit && property === 'og:description' && value.length >= msg_limit) {
                        value = `${value.slice(0, msg_limit)}${decodeHTMLEntities('&#8230;')}`;
                    }
                    acc[property] = value;
                }
                return acc;
            }, {
                'ogp_for_id': applies_to_id,
            });

            if ("og:description" in data || "og:title" in data || "og:image" in data) {
                return data;
            }
        }
    }
    return {};
}


export function getMediaURLsMetadata (text, offset=0) {
    const objs = [];
    if (!text) {
        return {};
    }
    try {
        URI.withinString(
            text,
            (url, start, end) => {
                if (url.startsWith('_')) {
                    url = url.slice(1);
                    start += 1;
                }
                if (url.endsWith('_')) {
                    url = url.slice(0, url.length-1);
                    end -= 1;
                }
                objs.push({ url, 'start': start+offset, 'end': end+offset });
                return url;
            },
            URL_PARSE_OPTIONS
        );
    } catch (error) {
        log.debug(error);
    }

    /**
     * @typedef { Object } MediaURLMetadata
     * An object representing the metadata of a URL found in a chat message
     * The actual URL is not saved, it can be extracted via the `start` and `end` indexes.
     * @property { Boolean } is_audio
     * @property { Boolean } is_image
     * @property { Boolean } is_video
     * @property { String } end
     * @property { String } start
     */
    const media_urls = objs
        .map(o => ({
            'end': o.end,
            'is_audio': isAudioURL(o.url),
            'is_image': isImageURL(o.url),
            'is_video': isVideoURL(o.url),
            'is_encrypted': isEncryptedFileURL(o.url),
            'start': o.start

        }));
    return media_urls.length ? { media_urls } : {};
}


export function getSpoilerAttributes (stanza) {
    const spoiler = sizzle(`spoiler[xmlns="${Strophe.NS.SPOILER}"]`, stanza).pop();
    return {
        'is_spoiler': !!spoiler,
        'spoiler_hint': spoiler?.textContent
    };
}

export function getOutOfBandAttributes (stanza) {
    const xform = sizzle(`x[xmlns="${Strophe.NS.OUTOFBAND}"]`, stanza).pop();
    if (xform) {
        return {
            'oob_url': xform.querySelector('url')?.textContent,
            'oob_desc': xform.querySelector('desc')?.textContent
        };
    }
    return {};
}

/**
 * Returns the human readable error message contained in a `groupchat` message stanza of type `error`.
 * @private
 * @param { XMLElement } stanza - The message stanza
 */
export function getErrorAttributes (stanza) {
    if (stanza.getAttribute('type') === 'error') {
        const error = stanza.querySelector('error');
        const text = sizzle(`text[xmlns="${Strophe.NS.STANZAS}"]`, error).pop();
        return {
            'is_error': true,
            'error_text': text?.textContent,
            'error_type': error.getAttribute('type'),
            'error_condition': error.firstElementChild.nodeName
        };
    }
    return {};
}

/**
 * Given a message stanza, find and return any XEP-0372 references
 * @param { XMLElement } stana - The message stanza
 * @returns { Reference }
 */
export function getReferences (stanza) {
    return sizzle(`reference[xmlns="${Strophe.NS.REFERENCE}"]`, stanza).map(ref => {
        const anchor = ref.getAttribute('anchor');
        const text = stanza.querySelector(anchor ? `#${anchor}` : 'body')?.textContent;
        if (!text) {
            log.warn(`Could not find referenced text for ${ref}`);
            return null;
        }
        const begin = ref.getAttribute('begin');
        const end = ref.getAttribute('end');
        /**
         * @typedef { Object } Reference
         * An object representing XEP-0372 reference data
         * @property { string } begin
         * @property { string } end
         * @property { string } type
         * @property { String } value
         * @property { String } uri
         */
        return {
            begin, end,
            'type': ref.getAttribute('type'),
            'value': text.slice(begin, end),
            'uri': ref.getAttribute('uri')
        };
    }).filter(r => r);
}

export function getReceiptId (stanza) {
    const receipt = sizzle(`received[xmlns="${Strophe.NS.RECEIPTS}"]`, stanza).pop();
    return receipt?.getAttribute('id');
}

/**
 * Determines whether the passed in stanza is a XEP-0280 Carbon
 * @private
 * @param { XMLElement } stanza - The message stanza
 * @returns { Boolean }
 */
export function isCarbon (stanza) {
    const xmlns = Strophe.NS.CARBONS;
    return (
        sizzle(`message > received[xmlns="${xmlns}"]`, stanza).length > 0 ||
        sizzle(`message > sent[xmlns="${xmlns}"]`, stanza).length > 0
    );
}

/**
 * Returns the XEP-0085 chat state contained in a message stanza
 * @private
 * @param { XMLElement } stanza - The message stanza
 */
export function getChatState (stanza) {
    return sizzle(
        `
        composing[xmlns="${NS.CHATSTATES}"],
        paused[xmlns="${NS.CHATSTATES}"],
        inactive[xmlns="${NS.CHATSTATES}"],
        active[xmlns="${NS.CHATSTATES}"],
        gone[xmlns="${NS.CHATSTATES}"]`,
        stanza
    ).pop()?.nodeName;
}

export function isValidReceiptRequest (stanza, attrs) {
    return (
        attrs.sender !== 'me' &&
        !attrs.is_carbon &&
        !attrs.is_archived &&
        sizzle(`request[xmlns="${Strophe.NS.RECEIPTS}"]`, stanza).length
    );
}

/**
 * Check whether the passed-in stanza is a forwarded message that is "bare",
 * i.e. it's not forwarded as part of a larger protocol, like MAM.
 * @param { XMLElement } stanza
 */
export function throwErrorIfInvalidForward (stanza) {
    const bare_forward = sizzle(`message > forwarded[xmlns="${Strophe.NS.FORWARD}"]`, stanza).length;
    if (bare_forward) {
        rejectMessage(stanza, 'Forwarded messages not part of an encapsulating protocol are not supported');
        const from_jid = stanza.getAttribute('from');
        throw new StanzaParseError(`Ignoring unencapsulated forwarded message from ${from_jid}`, stanza);
    }
}

/**
 * Determines whether the passed in stanza is a XEP-0333 Chat Marker
 * @private
 * @method getChatMarker
 * @param { XMLElement } stanza - The message stanza
 * @returns { Boolean }
 */
export function getChatMarker (stanza) {
    // If we receive more than one marker (which shouldn't happen), we take
    // the highest level of acknowledgement.
    return sizzle(`
        acknowledged[xmlns="${Strophe.NS.MARKERS}"],
        displayed[xmlns="${Strophe.NS.MARKERS}"],
        received[xmlns="${Strophe.NS.MARKERS}"]`,
        stanza
    ).pop();
}

export function isHeadline (stanza) {
    return stanza.getAttribute('type') === 'headline';
}

export function responseToStartCall(attrs) {
    const call_response = {
        appVersion: attrs.appVersion,
        call_type: attrs.call_type,
        message: SIGNAL_NAME.RINGING_CALL,
        peers: [],
        message_type: MESSAGE_TYPE.WEBRTC,
        signalName: SIGNAL_NAME.RINGING_CALL,
        sessionId: attrs.sessionId,
        type: SIGNAL_NAME.RINGING_CALL,
        sender: 'me',
    }
    return call_response
}

export async function responseToOffer(attrs) {
    attrs.message = JSON.parse(attrs.message)
    attrs.message.signalName = SIGNAL_NAME.ANSWER
    attrs.message.jid = attrs.contact_jid
    attrs.message.sdp_type = SIGNAL_NAME.OFFER.toLowerCase()
    attrs.message.callType = attrs.message.callType ? attrs.message.callType : attrs.call_type===CALL_TYPE_CONST.VIDEO ? CALL_TYPE.VIDEO : CALL_TYPE.AUDIO
    attrs.sdpdata = attrs.message
    const call_response = {
        appVersion: attrs.appVersion,
        call_type: attrs.call_type,
        message: attrs.message,
        peers: [],
        message_type: MESSAGE_TYPE.WEBRTC,
        signalName: SIGNAL_NAME.ANSWER,
        sessionId: attrs.sessionId,
        type: SIGNAL_NAME.ANSWER,
        sdpdata: attrs.message,
        sender: 'me'
    }
    console.log(`responseToOffer(attrs)`,attrs)
    return call_response
}

export async function handleIceCandidates(chat,attrs,event) {
    console.log(attrs,`under ice candidates event`,event)
                if (event.candidate && event.candidate.candidate && event.candidate.candidate.length > 0) {
                    attrs.message = JSON.stringify({
                        jid: attrs.jid,
                        candidate: event.candidate.candidate,
                        label: event.candidate.sdpMLineIndex,
                        sdpMLineIndex: event.candidate.sdpMLineIndex,
                        sdpMid: event.candidate.sdpMid,
                        id: event.candidate.sdpMid,
                        signalName: SIGNAL_NAME.ICE,
                        callType: attrs.call_type===CALL_TYPE_CONST.VIDEO ? CALL_TYPE.VIDEO : CALL_TYPE.AUDIO
                      })
                    attrs.body = JSON.stringify({
                        appVersion: attrs.appVersion,
                        call_type: attrs.call_type,
                        message: attrs.message,
                        peers: attrs.peers,
                        sessionId: attrs.sessionId,
                        type: SIGNAL_NAME.ICE,
                        signalName: SIGNAL_NAME.ICE,
                        callType: attrs.call_type===CALL_TYPE_CONST.VIDEO ? CALL_TYPE.VIDEO : CALL_TYPE.AUDIO
                    })
                    attrs.signalName= SIGNAL_NAME.ICE,
                    attrs.type = SIGNAL_NAME.ICE,
                    attrs.sender = 'me'
                    console.log(`under icecandidatehandle attrs`,attrs)
                    await chat.sendMessage(attrs)
                }
    return event
}

export async function handleIceCandidatesUpdate(chat,attrs,event) {
    console.log(`handleIceCandidatesUpdate`,chat,attrs,event)
    return event
}

export async function handleOnTrackUpdate(chat,attrs,event){
    console.log(attrs,`handleOnTrackUpdate`,chat,attrs,event)
    return event
}

export async function responseToRingingCall(attrs) {
    const call_response = {
        appVersion: attrs.appVersion,
        call_type: attrs.call_type,
        message: {
            sdp: [],
            sessionId: attrs.sessionId,
            call_type: attrs.call_type,
            callType: attrs.call_type===CALL_TYPE_CONST.VIDEO ? CALL_TYPE.VIDEO : CALL_TYPE.AUDIO,
            jid: attrs.from,
            signalName: SIGNAL_NAME.OFFER,
        },
        peers: [],
        message_type: MESSAGE_TYPE.WEBRTC,
        signalName: SIGNAL_NAME.OFFER,
        sessionId: attrs.sessionId,
        type: SIGNAL_NAME.OFFER,
        sender: 'me'
    }
    console.log(`responseToRingingCall(attrs)`,attrs)
    return call_response
}

export async function responseToAnswer(attrs) {
    attrs.message = JSON.parse(attrs.message)
    attrs.body = attrs.message
    if(!_converse[`${attrs.contact_jid}-answer`]) {
        _converse[`${attrs.contact_jid}-answer`] = []
    }
    _converse[`${attrs.contact_jid}-answer`].push({sdp:attrs.message.sdp, type: SIGNAL_NAME.OFFER.toLowerCase()})
    _converse[`${_converse.bare_jid}-${attrs.contact_jid}-pc`].setRemoteDescription(new RTCSessionDescription({sdp: attrs.message.sdp, type: SIGNAL_NAME.OFFER.toLowerCase()}))
    console.log(`responseToAnswer(attrs)`,attrs)
    return attrs
}

export async function responseToIce(attrs) {
    attrs.message = JSON.parse(attrs.message)
    attrs.body = attrs.message
    if(!_converse[`${attrs.contact_jid}-ice`]) {
        _converse[`${attrs.contact_jid}-ice`] = []
    }
    _converse[`${attrs.contact_jid}-ice`].push({
        sdpMLineIndex: attrs.message.label+'',
        candidate: attrs.message.candidate+''
      })
    for (let ice of _converse[`${attrs.contact_jid}-ice`]) {
        _converse[`${_converse.bare_jid}-${attrs.contact_jid}-pc`].addIceCandidate(new RTCIceCandidate({
          sdpMLineIndex: ice.label+'',
          candidate: ice.candidate+''
        }));
      }
    console.log(`responseToIce(attrs)`,attrs)
    return attrs
}

export function isInboxMessage (stanza) {
    return sizzle(`result[xmlns="${Strophe.NS.INBOX}"]`, stanza)
}

export function isReactionMessage (stanza) {
    return sizzle(`reaction`, stanza)
}

export async function isGroupExistInRoster (stanza) {
    return new Promise(async (resolve, reject) => {
        try{
            const chat_type = await isGroupStanza(stanza)
            if(chat_type){
                let room_jid = Strophe.getBareJidFromJid(stanza.getAttribute(`from`))
                if(!room_jid.includes(api.settings.get('muclight_domain'))){
                    room_jid = Strophe.getBareJidFromJid(stanza.getAttribute(`to`))
                }
                const rosterGroupData = _converse.roster.get(room_jid)
                if(rosterGroupData){
                    resolve(rosterGroupData)
                }else{
                    resolve(false)
                }
            }else{
                resolve(false)
            }
        }catch(error){
            resolve(false)
        }
    })
}

export async function isGroupStanza (stanza) {
    return new Promise(async (resolve, reject) => {
        try{
            const chat_type = stanza.getAttribute(`type`)
            if(chat_type===CHAT_TYPE.GROUP_CHAT){
                resolve(true)
            }
                resolve(false)
        }catch(error){
            resolve(false)
        }
    })
}

export async function isGroupAffiliationMessage (stanza) {
    return new Promise(async (resolve, reject) => {
        try{
            const chat_type = await isGroupStanza(stanza)
            if(chat_type){
                let room_jid = Strophe.getBareJidFromJid(stanza.getAttribute(`from`))
                    if(!room_jid.includes(api.settings.get('muclight_domain'))){
                        room_jid = Strophe.getBareJidFromJid(stanza.getAttribute(`to`))
                    }
                    const rosterGroupData = _converse.roster.get(room_jid)
                    if(rosterGroupData){
                        let selector = `x[xmlns="${Strophe.NS.MUCLIGHT_AFFILIATIONS}"]`;
                        const affilation = sizzle(selector, stanza).pop()
                        if(affilation){
                            resolve(affilation)    
                        }else{
                            resolve(false)
                        }
                    }else{
                        resolve(false)
                    }
            }else{
                resolve(false)
            }
        }catch(error){
            resolve(false)
        }
    })
}

export async function fetchGroupAffiliations (stanza) {
    return new Promise(async (resolve, reject) => {
        try{
            const is_group_affiliation_message = await isGroupAffiliationMessage(stanza)
            if(is_group_affiliation_message){
                let room_jid = Strophe.getBareJidFromJid(stanza.getAttribute(`from`))
                    if(!room_jid.includes(api.settings.get('muclight_domain'))){
                        room_jid = Strophe.getBareJidFromJid(stanza.getAttribute(`to`))
                    }
                    const rosterGroupData = _converse.roster.get(room_jid)
                    if(rosterGroupData){
                        let selector = `user`;
                        const user = sizzle(selector, stanza)
                        if(user.length){
                            const affilation_data = []
                            for (const userData of Object.entries(user)){
                                const popuserData = user[0]
                                if(popuserData?.textContent===_converse.bare_jid && popuserData?.getAttribute('affiliation')=="none"){
                                    affilation_data.push({affiliation:popuserData?.getAttribute('affiliation'),jid:popuserData?.textContent})
                                    resolve(affilation_data)
                                }else{
                                    affilation_data.push({affiliation:popuserData?.getAttribute('affiliation'),jid:popuserData?.textContent})
                                }
                            }
                            resolve(affilation_data)    
                        }else{
                            resolve(false)
                        }
                    }else{
                        resolve(false)
                    }
            }else{
                resolve(false)
            }
        }catch(error){
            resolve(false)
        }
    })
}

export function isMAMMessage (stanza) {
    return sizzle(`result[xmlns="${Strophe.NS.MAM}"]`, stanza)?.length>0
}
export function isStoryMessage (stanza) {
    return sizzle(`story_status`, stanza)
}
export function isForwardedMessage (stanza) {
    return sizzle(`isForwarded[xmlns="${Strophe.NS.JABBER_XMPP_FORWARD}"]`, stanza)?.length>0
}
export function isStorySeen (stanza) {
    return sizzle(`story_seen[xmlns="${Strophe.NS.JABBER_STORY_SEEN}"]`, stanza)?.length>0
}

export function isServerMessage (stanza) {
    if (sizzle(`mentions[xmlns="${Strophe.NS.MENTIONS}"]`, stanza).pop()) {
        return false;
    }
    const from_jid = stanza.getAttribute('from');
    if (stanza.getAttribute('type') !== 'error' && from_jid && !from_jid.includes('@')) {
        // Some servers (e.g. Prosody) don't set the stanza
        // type to "headline" when sending server messages.
        // For now we check if an @ signal is included, and if not,
        // we assume it's a headline stanza.
        return true;
    }
    return false;
}

/**
 * Determines whether the passed in stanza is a XEP-0313 MAM stanza
 * @private
 * @method isArchived
 * @param { XMLElement } stanza - The message stanza
 * @returns { Boolean }
 */
export function isArchived (original_stanza) {
    return !!sizzle(`message > result[xmlns="${Strophe.NS.MAM}"]`, original_stanza).pop();
}


/**
 * Returns an object containing all attribute names and values for a particular element.
 * @method getAttributes
 * @param { XMLElement } stanza
 * @returns { Object }
 */
export function getAttributes (stanza) {
    return stanza.getAttributeNames().reduce((acc, name) => {
        acc[name] = Strophe.xmlunescape(stanza.getAttribute(name));
        return acc;
    }, {});
}
