/**
 * @copyright 2022, the Converse.js contributors
 * @license Mozilla Public License (MPLv2)
 */
import './components/chat-header.js';
import { converse } from '@converse/headless/core';


converse.plugins.add('converse-chat-header', {
    dependencies: [],

    initialize () {
    }
});