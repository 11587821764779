import tpl_chat_header from "../templates/chat-header.js";
import tpl_chat_header_dracula from "../templates/themes/dracula/chat-header-dracula.js";
import { CustomElement } from 'shared/components/element.js';
import { _converse, api } from '@converse/headless/core.js';
import { THEMES } from "../../../shared/constants.js";


class ChatHeader extends CustomElement {

    render () { // eslint-disable-line class-methods-use-this
        const theme = _converse.xmppstatus?.get('theme');
        return theme === THEMES.DRACULA ? tpl_chat_header_dracula() : tpl_chat_header();
    }
}

api.elements.define('converse-chat-header', ChatHeader);
