import tpl_add_occupants_muclight from "./templates/add-occupants-muclight.js";
import BaseModal from "plugins/modal/modal.js";
import { __ } from 'i18n';
import { _converse, api, converse } from "@converse/headless/core";
import './core.js'
import '../styles/add-muclight-modal.scss';
import { isNull } from "lodash-es";
import { html } from "lit";

const u = converse.env.utils;
const { Strophe, $iq, sizzle } = converse.env;


export default class AddOccupantsMUCLIGHTModal extends BaseModal {

    initialize () {
        super.initialize();
        this.listenTo(this.model, 'change:muclight_domain', () => this.render());
        api.listen.on('groupInfoRequestUpdate', () => {
            this.render();
            this.renderModal()
        });
        this.muclight_roomid_policy_error_msg = null;
        this.render();
    }

    renderModal () {
        return tpl_add_occupants_muclight(this);
    }

    getModalTitle () { // eslint-disable-line class-methods-use-this
        return __('Add Participants');
    }

    parseRoomDataFromEvent (form) { // eslint-disable-line class-methods-use-this
        const data = new FormData(form);
        const jid = data.get('chatroom')?.trim();
        let nick;
        if (api.settings.get('locked_muclight_nickname')) {
            nick = _converse.getDefaultMUCLIGHTNickname();
            if (!nick) {
                throw new Error("Using locked_muclight_nickname but no nickname found!");
            }
        } else {
            nick = data.get('nickname').trim();
        }
        return {
            'jid': jid,
            'nick': nick
        }
    }

    /*
    *   we make a json here by collecting data from a form
    *   form param has all the input fields
    *   Example to use : new FormData(form);
    *   required parms and valude are [groupname,groupsubject] :: Data Type String,
    *   required atleast 1 should be selected as jids :: Array
    *   Example to use/call this.parseMucLightRoomDataFromEvent(form) or import add-muclight.js
    *   any and make an object to call it.
    */
    parseMucLightRoomDataFromEvent (form) { // eslint-disable-line class-methods-use-this
        const data = new FormData(form);
        const groupname = data.get('groupname')?.trim();
        let groupsubject = data.get('groupsubject')?.trim();

        let jids = form.querySelectorAll('input[name="jids[]"]:checked')
        let dataObj = {
            name: groupname,
            subject: groupsubject,
            occupants: this.getCheckedBoxes(jids)
        }
        return dataObj

    }
    /*
    *   we make a json here by collecting data from a muc-info received from server
    *   the data which we receive from server we will pass in parseMucLightInfo to gather information
    *   Example to use : parseMucLightInfo(result);
    *   it must have attribute name as `type` and it's value should be result to verify
    *   that the informaiton found for the sent group jid or else not found or any error will
    *   return by server
    */
    parseMucLightInfo(result) {
        let groupParsedData = {}
        if(result.getAttribute(`type`)==`result`){
            let configurationElements = result.querySelector(`configuration`).childNodes
            for(const configurationElementsData of configurationElements){
                groupParsedData[configurationElementsData.nodeName] = configurationElementsData?.textContent
            }
            groupParsedData[`occupants`] = {}
            let occupantsElements = result.querySelector(`occupants`).childNodes
            let occupantsElementsDataI = 0
            for(const occupantsElementsData of occupantsElements){
                groupParsedData[`occupants`][occupantsElementsDataI] = {jid:occupantsElementsData?.textContent,type:occupantsElementsData.getAttribute(`affiliation`)}
                occupantsElementsDataI++
            }
        }
        return groupParsedData
    }

    /*
    *   collecting data from form to return only selected data by user in occupants array
    *   Example to use : getCheckedBoxes(checkboxes)
    *   it will collect only those jids which are selected by user to add occupants in room
    */
    getCheckedBoxes(checkboxes) {
        var checkboxesChecked = [];
        // loop over them all
        for (var i=0; i<checkboxes.length; i++) {
           // And stick the checked ones onto an array...
           if (checkboxes[i].checked) {
              checkboxesChecked.push(checkboxes[i].value);
           }
        }
        // Return the array if it is non-empty, or null
        return checkboxesChecked.length > 0 ? checkboxesChecked : [];
    }
    generateUserJid (jid) {
        if(isNull(jid)){
            return jid;
          }
          return `${Strophe.getNodeFromJid(jid)}@${_converse.domain}`
    }
    
    /*
    *   createMuc to call server to execute or request to create new room
    *   with passed some params which allows to configure room settings
    *   Example to use : createMuc(ev) ev has as clickable event and data which will
    *   collect submitted form data also to know what use has subbmited to request
    */
    createMuc(ev) {
        ev?.preventDefault();
        return new Promise(async (resolve, reject) => {
            try{
                const data = this.parseMucLightRoomDataFromEvent(ev.target);
                if(!data.name)
                {
                    reject(`Group Name Required`)
                }
                let muclight_domain = api.settings.get('muclight_domain')
                let groupJid = `${u.getUniqueId()}@${muclight_domain}`
                var iq = $iq({
                  type: "set",
                  id: u.getUniqueId(),
                  to: groupJid
                }).c("query", { xmlns: Strophe.NS.MUCLIGHT_CREATE })
                  .c("configuration")
                  .c("created_by").t(_converse.connection.jid).up()
                  .c("created_at").t(Date.now().toString()).up()
                  .c("hide_numbers").t("false").up()
                  .c("members_max_count").t("500").up()
                  .c("admin_can_post_only").t("false").up()
                  .c("is_forward_allowed").t("false").up()
                  .c("public_group").t("false").up()
                  .c("public_can_post_text").t("false").up()
                  .c("post_as_anonymous").t("false").up()
                  .c("group_creator_jid").t(Strophe.getBareJidFromJid(_converse.connection.jid)).up()
                  .c("roomname").t(data.name).up()
                  .c("room_description").t(data.subject).up()
                  .up()
                  .c("occupants");
                for (let contact of data.occupants) {
                  iq.c("user", { affiliation: Strophe.NS.MUCLIGHT_AFFILIATION_TYPE_MEMBER}).t(this.generateUserJid(contact)).up()
                }
                iq.up()
                  .up()
                  .up();
                let datareturned = await api.sendIQ(iq)
                let getgroupinfo = await u.getGroupInfo(groupJid)
                _converse.roster.create({ nickname:getgroupinfo.roomname, info: getgroupinfo, image:getgroupinfo.image_url, ask:"none", groups:[], jid:groupJid, subscription: "both" }, {sort: false});
    
                resolve(getgroupinfo)
                // api.rooms.open(jid, Object.assign(data, {jid}), true);
                ev.target.reset();
                this.modal.hide();
            }catch(error){
                reject(`createMuc error : `,error)
            }
        })
    }
    /*
    *   addParticipants will call server to execute or request to add new member in existing
    *   group
    *   Example to use : addParticipants(data) data is an object which holdes groupjid as
    *   data.to and data.jid as member we wish to add in group
    */
    async addParticipants (ev) {
        ev?.preventDefault();
        return new Promise(async (resolve, reject) => {
            try{
                const data = this.parseMucLightRoomDataFromEvent(ev.target);
                var iq = $iq({
                    type: "set",
                    id: u.getUniqueId(),
                    to: this.model.get(`jid`)
                  }).c("query", { xmlns: Strophe.NS.MUCLIGHT_AFFILIATIONS });
                  for (let contact of data.occupants) {
                    iq.c("user", { affiliation: Strophe.NS.MUCLIGHT_AFFILIATION_TYPE_MEMBER}).t(this.generateUserJid(contact)).up()
                  }
                  iq.up()
                    .up()
                let datareturned = await api.sendIQ(iq)
                if(datareturned?.getAttribute(`type`)=="result"){
                    await _converse.roster.get(this.model.get(`jid`)).openChat(true)
                    api.trigger(`groupInfoRequestUpdate`)
                }
                resolve(datareturned)
                ev.target.reset();
                this.modal.hide();
                }catch(error){
                reject(error)
            }
        })
    }

}

api.elements.define('converse-add-occupants-muclight-modal', AddOccupantsMUCLIGHTModal);
